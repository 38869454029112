const clients = $('#clients');

if (clients.length) {
	const waiting = Promise.all([
		new Promise((res, rej) => {
			document.addEventListener('DOMContentLoaded', () => {
				res();
			}, false);
		}),
		new Promise((res, rej) => {
			AR.events.on('svgRenderedAll', $elements => {
				res($elements);
			});
		})
	]);

	AR.waitComponents([], () => {
		init(clients, waiting);
	});
}

async function init(parent, resources) {
	const {CProjects_clients} = await import(/* webpackChunkName: "CProjects_clients" */ './main.js');
	const cProject_clients = new CProjects_clients(parent);
	cProject_clients.init(resources);

	AR.pushComponent(cProject_clients, 'cProject_clients');
}

const config = require('./config.yaml');
const {throttle} = require('throttle-debounce');
const CAccordions = require('../../index.js');

class CAccordions_base extends CAccordions {
	constructor() {
		super();
		this.config = config;
	}

	init() {
		const $accordions = $('.js-accordion');
		this.initAccordions($accordions);
	}

	initAccordions($accordions) {
		if ($accordions.length) {
			this.hashStack = [];

			$accordions.each((i, elem) => {
				const $elem = $(elem);
				const isNoHash = $elem.hasClass('js-accordion--no-hash');
				const $controls = $elem.children('.js-accordion-control').find('.js-accordion-control-item');
				const $section = $elem.children('.js-accordion-section');
				const $buttons = $section.children('.js-accordion-header').find('.js-accordion-button');

				if (this.config.showArticleCloseBtnCondition) {
					const $articles = $section.children('.js-accordion-article');
					this.createCloseArticleButtons($articles, isNoHash);
				}

				if ($controls.length) {
					$controls.on('click', $.proxy(this, 'expandCollapse'));
				}

				$buttons.on('click', $.proxy(this, 'toggle', isNoHash));
			});

			const $svg = $('[data-svg]');
			if ($svg.length) {
				AR.events.on('svgRenderedAll', () => {
					const openedByHash = this.checkHash();

					if (!openedByHash) {
						const $accordionsWithDataOpen = $accordions.filter((i, elem) => $(elem).data('open'));

						if ($accordionsWithDataOpen.length) {
							this.openByDataOpen($accordionsWithDataOpen);
						}
					}
				});
			} else {
				const openedByHash = this.checkHash();

				if (!openedByHash) {
					const $accordionsWithDataOpen = $accordions.filter((i, elem) => $(elem).data('open'));

					if ($accordionsWithDataOpen.length) {
						this.openByDataOpen($accordionsWithDataOpen);
					}
				}
			}
		}
	}

	openByDataOpen($accordions) {
		$accordions.each((i, elem) => {
			const $elem = $(elem);
			const isNoHash = $elem.hasClass('js-accordion--no-hash');
			const itemNum = $elem.data('open');
			const $targetButton = $elem
				.children('.js-accordion-section')
				.children('.js-accordion-header')
				.find('.js-accordion-button')
				.eq(itemNum - 1);

			this.open($targetButton);

			if (!isNoHash) {
				this.setHashAndPush($targetButton.attr('href'));
			}
		});
	}

	expandCollapse(event) {
		const $control = $(event.currentTarget);
		const action = $control.hasClass('js-accordion-control-item--expand') ? 'expand' : 'collapse';
		const $accordion = $control.closest('.js-accordion');
		const $targetButtons = action == 'expand' ? $accordion.find('.js-accordion-button:not(.is-expand)') : $accordion.find('.js-accordion-button.is-expand');

		if ($targetButtons.length) {
			$targetButtons.each((i, elem) => {
				const $elem = $(elem);
				const isNoHash = $elem.closest('.js-accordion').hasClass('js-accordion--no-hash');

				if (action == 'expand') {
					this.open($elem);
					if (!isNoHash) {
						this.setHashAndPush($elem.attr('href'));
					}
				} else {
					this.close($elem, isNoHash);
				}
			});
		}
	}

	createCloseArticleButtons($articles, isNoHash) {
		$articles.each((i, article) => {
			const $article = $(article);
			const $articleClose = $('<button class="b-accordion__article-close js-accordion-article-close"></button>');
			const $button = $article.siblings('.js-accordion-header').find('.js-accordion-button');
			$article.append($articleClose);

			$articleClose.on('click', $.proxy(this, 'close', $button, isNoHash));
		});

		if (this.config.showArticleCloseBtnCondition == 'longerThanWindow') {
			$(window).resize(throttle(150, () => {
				const $expandedArticles = $('.js-accordion-article.is-expand');

				if ($expandedArticles.length) {
					$expandedArticles.each((i, elem) => {
						this.showArticleClose($(elem));
					});
				}
			}));
		}
	}

	closeSiblings($button) {
		$button
			.closest('.js-accordion-section')
			.siblings('.js-accordion-section')
			.find('.js-accordion-button.is-expand')
			.each((i, elem) => {
				this.close($(elem));
			});
	}

	showArticleClose($article) {
		const windowHeight = $(window).height();
		const $header = $article.siblings('.js-accordion-header');
		const sectionHeight = $header.height() + $article.innerHeight();
		const $articleClose = $article.children('.js-accordion-article-close');

		if ($articleClose.hasClass('is-hidden') && sectionHeight > windowHeight) {
			$articleClose.removeClass('is-hidden');
		} else if (!$articleClose.hasClass('is-hidden') && sectionHeight < windowHeight) {
			$articleClose.addClass('is-hidden');
		}
	}

	toggle(isNoHash, event) {
		event.preventDefault();
		const $button = $(event.currentTarget);

		if ($button.hasClass('is-expand')) {
			this.close($button, isNoHash);
		} else {
			this.open($button);

			if (!isNoHash) {
				this.setHashAndPush($button.attr('href'));
			}

			if (this.config.closeSiblings) {
				this.closeSiblings($button);
			}
		}
	}

	open($button, duration) {
		const $section = $button.closest('.js-accordion-section');
		const $article = $section.children('.js-accordion-article');
		let extraScroll = 0;

		if (this.config.closeSiblings) {
			const $prevOpened = $section
				.prevAll('.js-accordion-section')
				.children('.js-accordion-header')
				.find('.js-accordion-button.is-expand');

			if ($prevOpened.length) {
				$prevOpened
					.closest('.js-accordion-header')
					.siblings('.js-accordion-article')
					.each((i, elem) => {
						extraScroll -= $(elem).innerHeight();
					});
			}
		}

		if (this.config.showArticleCloseBtnCondition == 'longerThanWindow') {
			this.showArticleClose($article);
		}

		$button
			.addClass('is-expand');
		$article
			.addClass('is-expand')
			.velocity(this.config.animation.open.effect, {
				duration: duration || this.config.animation.open.duration,
				begin: () => AR.events.emit('onAccordionsBaseOpenStart', $button),
				complete: () => AR.events.emit('onAccordionsBaseOpenEnd', $button)
			});

		if (this.config.scrollOnOpen) {
			this.scrollTo($button, extraScroll);
		}
	}

	close($button, isNoHash = false) {
		const $section = $button.closest('.js-accordion-section');
		const $article = $section.children('.js-accordion-article');

		$button
			.removeClass('is-expand');
		$article
			.removeClass('is-expand')
			.velocity(this.config.animation.close.effect, {
				duration: this.config.animation.close.duration,
				begin: () => AR.events.emit('onAccordionsBaseCloseStart', $button),
				complete: () => AR.events.emit('onAccordionsBaseCloseEnd', $button)
			});

		if (!isNoHash) {
			const href = $button.attr('href');
			this.removeFromHashStack(href);
			this.removeHash(href);
		}

		if (this.config.closeInsideAccordionsOnClose) {
			$article
				.find('.js-accordion-button.is-expand')
				.each((i, elem) => this.close($(elem)));
		}

		if (this.config.scrollOnClose) {
			this.scrollTo($button);
		}
	}

	openSelfAndParents($parents, $button, hash) {
		const parentsLength = $parents.length;

		$parents.each((i, elem) => {
			const $elem = $(elem);
			const isNoHash = $elem.closest('.js-accordion').hasClass('js-accordion--no-hash');
			const $button = $elem.children('.js-accordion-header').find('.js-accordion-button');

			if (parentsLength - 1 == i || parentsLength == 1) {
				this.open($button);
			} else {
				this.open($button, '0');
			}

			if (!isNoHash) {
				this.pushToHashStack($button.attr('href'));
			}
		});

		this.hashStack.reverse();
	}

	checkHash() {
		const hash = window.location.hash;
		const $targetElem = $(`[href="${hash}"]`);
		const isNoHash = $targetElem.closest('.js-accordion').hasClass('js-accordion--no-hash');
		const $parents = $targetElem.parents('.b-accordion__section');

		if ($targetElem.length && !($parents.length == 1 && isNoHash)) {
			this.openSelfAndParents($parents, $targetElem, hash);

			return true;
		} else {
			return false;
		}
	}

	setHashAndPush(href) {
		this.setHash(href);
		this.pushToHashStack(href);
	}

	setHash(hash) {
		window.location.hash = hash;
	}

	removeHash() {
		const previousHash = this.hashStack[this.hashStack.length - 1];
		window.location.hash = previousHash || '!';
	}

	pushToHashStack(hash) {
		this.hashStack.push(hash);

		if (!config.closeInsideAccordionsOnClose) {
			const $button = $(`.js-accordion-button[href="${hash}"]`);
			const $article = $button.closest('.js-accordion-section').children('.js-accordion-article');
			const $expandedArticles = $article.find('.js-accordion-button.is-expand');

			if ($expandedArticles.length) {
				$expandedArticles.each((i, elem) => {
					const href = $(elem).attr('href');
					this.hashStack.push(href);
				});
			}
		}
	}

	removeFromHashStack(href) {
		const hashIndex = this.hashStack.indexOf(href);

		if (hashIndex > -1) {
			this.hashStack.splice(hashIndex, 1);
		}
	}

	scrollTo($button, extraScroll = 0) {
		const $nav = $('.l-layout-wrapper__navigation');
		let navScroll = (window.innerWidth <= 798 && $nav.length) ? $nav.height() * -1 : 0;

		const topPos = $button.offset().top;

		$('html').velocity('scroll', {
			offset: topPos + extraScroll + navScroll + 'px',
			duration: this.config.animation.scrollTo.duration,
			mobileHA: false
		});
	}
}

const cAccordions_base = new CAccordions_base();

cAccordions_base.init();

AR.pushComponent(cAccordions_base, 'cAccordions_base');

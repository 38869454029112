/**
 * Класс реализует базовую логику для компонента up-button
 */
class CUpButton {
	// В параметрах передается config.yaml и имя необходимого варианта
	constructor(config, componentVariantName = '') {
		// Название создаваемого глобального события
		this.componentVariantName = componentVariantName;
		// Получаем файл config.yaml
		this.config = config;
	}
	// Вызов метода появления/исчезновения кнопки - "Вверх"
	onToggleUpButtonVisible() {
		this.toggleUpButtonVisible();
	}
	// Вызов метода прокрутки вверх
	onScrollToUp() {
		this.scrollToUp();
	}
	// Метод, осуществляющий появление, исчезновение кнопки вверх
	toggleUpButtonVisible() {
		// Если текущая высота скролла, относительно от верхней части окна, больше высоты блока с контентом
		if (this.$window.scrollTop() > this.scrollTopValue) {
			// Если у главного блока значение opacity = 0
			if (this.$upButton.css('opacity') == 0) {
				this.$upButton.velocity('stop');

				// Запустить анимацию появления главного блока, со скоростью, заданной в файле config.yaml
				this.$upButton.velocity('fadeIn', {duration: this.config.upButtonAnimation.buttonFadeIn});
			}
		} else {
			// Если у главного блока значение opacity = 1
			if (this.$upButton.css('opacity') == 1) {
				this.$upButton.velocity('stop');

				// Запустить анимацию исчезновения главного блока, со скоростью, заданной в файле config.yaml
				this.$upButton.velocity('fadeOut', {duration: this.config.upButtonAnimation.buttonFadeOut});
			}
		}
	}
	// Метод, осуществляющий прокрутку вверх
	scrollToUp() {
		this.$body.velocity('stop');

		// Запустить анимацию скролла вверх, со скоростью заданной в файле - config.yaml
		this.$body.velocity('scroll', {
			duration: this.config.upButtonAnimation.scrollToUpDuration,
			offset: 0,
			easing: 'ease-in-out',
			// В начале анимации, создать событие - "onUpButtonSideScrollUpStart"
			begin: () => {
				AR.events.emit(`on${this.componentVariantName}ScrollUpStart`);
			},
			// В конце анимации, создать событие - "onUpButtonSideScrollUpEnd"
			complete: () => {
				AR.events.emit(`on${this.componentVariantName}ScrollUpEnd`);
			}
		});
	}
}

module.exports = CUpButton;

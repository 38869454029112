/**
 * Класс реализует базовую логику для компонента form
 */
class CLayout {
	constructor() {

	}

	/**
	 * Инициализация логики работы компонента
	 */
	init() {

	}
}

module.exports = CLayout;

AR.waitComponents([], () => {
	const cLayout = new CLayout();
	// Вызов метода со всеми событиями
	cLayout.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cLayout, 'cLayout');
});

import 'particles.js';

const colors = {
	'body--wine-25-cabernet': '#8BC2A5',
	'body--wine-25-pinot': '#4DADF7',
};

class CWine25 {
	constructor() {

	}

	init() {
		const key = document.querySelector('body').className.split(' ').find(s => s.startsWith('body--wine-25-'));
		const color = colors[key] || '#000000';

		setTimeout(() => {
			this.animate(color);
			this.initObserver();
		}, 1);
	}

	animate(color) {
		particlesJS('particles-js', {
			particles: {
				number: {
					value: 105,
				},
				color: {
					value: color,
				},
				shape: {
					type: 'circle',
				},
				opacity: {
					value: 0.6,
					random: true,
					anim: {
						enable: true,
						speed: 1,
						opacity_min: 0.1,
						sync: false
					}
				},
				size: {
					value: 6,
					random: true,
					anim: {
						enable: false,
						speed: 60,
						size_min: 0.1,
						sync: false
					}
				},
				line_linked: {
					enable: false,
				},
				move: {
					enable: true,
					speed: 3,
					direction: 'bottom',
					random: false,
					straight: false,
					out_mode: 'out',
					bounce: false,
					attract: {
						enable: false,
						rotateX: 600,
						rotateY: 1200
					}
				}
			},
			interactivity: {
				detect_on: 'canvas',
				events: {
					onhover: {
						enable: true,
						mode: 'bubble'
					},
					onclick: {
						enable: true,
						mode: 'push'
					},
					resize: true
				},
				modes: {
					grab: {
						distance: 400,
						line_linked: {
							opacity: 0.5
						}
					},
					bubble: {
						distance: 400,
						size: 4,
						duration: 0.3,
						opacity: 1,
						speed: 3
					},
					repulse: {
						distance: 200,
						duration: 0.4
					},
					push: {
						particles_nb: 4
					},
					remove: {
						particles_nb: 2
					}
				}
			},
			retina_detect: true
		});
	}

	initObserver() {

		const innerBlock = document.querySelector('.wine-25__content-inner');
		let targetElement = document.querySelectorAll('.js-fade-in-text-block');

		const callback = (entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					observer.unobserve(entry.target);
					entry.target.classList.add('fade-in');
				}
			});

			let fadeInElement = document.querySelectorAll('.js-fade-in-text-block.fade-in');

			if (fadeInElement?.length >= targetElement?.length) {
				innerBlock.removeAttribute('style');
			}
		};

		const options = {
			root: document.querySelector('.wine-25'),
			rootMargin: '0px',
			threshold: 0,
		};

		const observer = new IntersectionObserver(callback, options);

		targetElement.forEach((i) => {
			if (i) {
				observer.observe(i);
			}
		});

		setTimeout(() => {
			innerBlock.style.height = innerBlock.offsetHeight + 'px';
		}, 300);
	}
}

AR.waitComponents([], () => {
	const wine25Page = document.querySelector('.body--wine-25');

	if (wine25Page) {
		const cWine25 = new CWine25;
		cWine25.init();
		AR.pushComponent(cWine25, 'cWine25');

		const showTextButton = document.querySelector('.js-show-text');

		showTextButton.addEventListener('click', function () {
			const textBlock = document.querySelector('.wine-25__content');
			textBlock.style.paddingTop = 60 + 'px';
			textBlock.scrollIntoView({
				behavior: 'smooth'
			});
		});
	}
});

module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'menu/vertical-accordeon' && templateVars.config ?
				templateVars.config :
				{
	"titleParam": [
		"menuTitle",
		"shortTitle"
	],
	"depthLevel": 1,
	"expandable": true,
	"closable": true,
	"documentClickClose": true,
	"highlightOnlyCurrent": true,
	"hidePreviousListOnExpand": false,
	"hideInnerOnClose": true,
	"fixedMenu": false,
	"animation": {
		"open": {
			"transition": "slideDown",
			"duration": 200
		},
		"close": {
			"transition": "slideUp",
			"duration": 200
		}
	},
	"additionalLinks": {
		"ar": {
			"ru": [
				{
					"title": "Дополнительная информация",
					"link": "${rootUrl}/additional-information/",
					"modifier": "cl-item",
					"targetBlank": false
				}
			],
			"en": [
				{
					"title": "Additional information",
					"link": "${rootUrl}/additional-information/",
					"modifier": "cl-item",
					"targetBlank": false
				}
			]
		}
	},
	"categories": [
		{
			"name": "first",
			"title": "Годовые отчеты",
			"link": "/ru/services/reporting",
			"icon": "https://cdn.zebra-group.ru/images/icons/slide-0_icon-1.svg"
		},
		{
			"name": "second",
			"title": "ESG, ОУР и&nbsp;КСО",
			"link": "/ru/services/esg",
			"icon": "https://cdn.zebra-group.ru/images/icons/slide-0_icon-2.svg"
		},
		{
			"name": "third",
			"title": "Онлайн-отчеты",
			"link": "/ru/services/digital-reporting",
			"icon": "https://cdn.zebra-group.ru/images/icons/slide-0_icon-3.svg"
		},
		{
			"name": "fourth",
			"title": "ESEF-отчеты (IXBRL)",
			"link": "/ru/services/ixbrl",
			"icon": "https://cdn.zebra-group.ru/images/icons/slide-0_icon-4.svg"
		},
		{
			"name": "fifth",
			"title": "Определение существенных&nbsp;тем",
			"link": "/ru/services/essential-topics",
			"icon": "https://cdn.zebra-group.ru/images/icons/slide-0_icon-5.svg"
		},
		{
			"name": "sixth",
			"title": "Databook",
			"link": "/ru/services/databook",
			"icon": "https://cdn.zebra-group.ru/images/icons/slide-0_icon-6.svg"
		}
	]
};
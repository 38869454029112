const config = require('./config.yaml');

/**
 * Класс реализует базовую логику для компонента search-form
 */
class CSearchForm {
	constructor() {
		this.config = config;
	}

	onFormSubmit(formObj, event) {
		this.checkAndSearch(formObj, event);
	}

	onCloseButton(formObj) {
		this.closeInput(formObj);
	}

	setFocusState(formObj, event) {
		if (formObj.$searchLabel && formObj.$searchLabel.length) {
			return $.Velocity(formObj.$searchLabel, this.config.animation.label.hide.effect, this.config.animation.label.hide.duration);
		} else {
			return Promise.resolve();
		}
	}

	setBlurState(formObj, event) {
		const inputText = formObj.$searchInput.val();

		if (inputText.length) {
			formObj.$searchInput.val(this.trimText(inputText));
		} else {
			formObj.$searchLabel.velocity(this.config.animation.label.show.effect, this.config.animation.label.show.duration);
		}
	}

	getSearchLink(searchQuery, type = 'searchLink') {
		return config[type]
			.replace('${rootUrl}', templateVars.siteLangRootUrlRel)
			.replace('${searchQuery}', encodeURIComponent(searchQuery));
	}

	trimText(text) {
		return text.trim();
	}

	checkAndSearch(formObj, event) {
		event.preventDefault();
		const text = formObj.$searchInput.val();

		if (text.length < 3) {
			this.setErrorState(formObj.$form);
			this.showMessage(formObj);
			formObj.$searchInput.on('input', $.proxy(this, 'onInputTyping', formObj));
		} else {
			this.unsetErrorState(formObj.$form);
			this.doSearch(text);
		}
	}

	showMessage(formObj) {
		formObj.$searchMessage.velocity(this.config.animation.message.show.effect, this.config.animation.message.show.duration);
	}

	hideMessage(formObj) {
		formObj.$searchMessage.velocity(this.config.animation.message.hide.effect, this.config.animation.message.hide.duration);
	}

	onInputTyping(formObj) {
		if (formObj.$searchInput.val().length >= 3) {
			formObj.$searchInput.off('input', $.proxy(this, 'onInputTyping'));
			this.hideMessage(formObj);
		}
	}

	doSearch(text) {
		window.location = this.getSearchLink(text);
	}

	/**
	 * Выставляет класс ошибки на указанный поисковый контейнер
	 * @param $search
	 */
	setErrorState($search) {
		if ($search.length) {
			$search.addClass('is-error');
		} else {
			return false;
		}
	}

	/**
	 * Удалаяет класс ошибки с указанного поискового контейнера
	 * @param $search
	 */
	unsetErrorState($search) {
		if ($search.length) {
			$search.removeClass('is-error');
		} else {
			return false;
		}
	}

	initQuickSearch(formObj) {
		if (this.config.quickSearch) {
			formObj.$searchInput.focusin(() => {
				this.fillInTitles(formObj);
			});

			if ($('html').hasClass('feature-no-oninput')) { // вызов обработки поискового запроса для IE8
				formObj.$searchInput.on('propertychange input', (ev) => {
					this.fillInTitles(formObj);
				});
			} else {
				formObj.$searchInput.on('input', () => { // вызов обработки поискового запроса
					this.fillInTitles(formObj);
				});
			}
		}
	}

	fillInTitles(formObj) { //отправка и получение поискового запроса, отображение и скрытие формы
		const searchQuery = formObj.$searchInput.val();

		if (searchQuery.length >= 3) {
			$.getJSON(this.getSearchLink(searchQuery, 'quickSearchLink'), (data) => {
				if (data.length) {
					let searchResults = '';

					data.slice(0, config.maxElemDisplay - 1)
						.forEach((item, index) => {
							if (item.body.length) {
								searchResults += `<a href="${item.link}" class="search__result-item">${item.body}</a>`;
							} else {
								searchResults += `<a href="${item.link}" class="search__result-item">${item.title}</a>`;
							}
						});

					formObj.$searchResultList.html(searchResults);
					formObj.$searchResultButton.attr('href', this.getSearchLink(searchQuery));

					if (formObj.$searchResult.is(':hidden')) {
						formObj.$searchResult.velocity(this.config.animation.searchResult.show.effect, this.config.animation.searchResult.show.duration);
					}
				} else {
					this.hideSearchResult(formObj);
				}
			});
		} else {
			this.hideSearchResult(formObj);
		}
	}

	disableTypingListening(formObj) {
		formObj.$searchInput.off('input', $.proxy(this, 'onInputTyping', formObj));
	}

	hideSearchResult(formObj) {
		if (formObj.$searchResult && formObj.$searchResult.is(':visible')) {
			formObj.$searchResult.velocity(this.config.animation.searchResult.hide.effect, this.config.animation.searchResult.hide.duration);
		}
	}

	initSpeechRecognition(formObj) {
		window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
		const recognition = new SpeechRecognition;
		recognition.interimResults = true;
		recognition.lang = templateVars.lang;

		formObj.$searchSpeechButton.on('click', event => this.startSpeechRecognize(formObj, recognition, event));
		recognition.addEventListener('audiostart', event => this.onSpeechRecognizeStart(formObj));
		recognition.addEventListener('end', event => this.onSpeechRecognizeEnd(formObj, event));
		recognition.addEventListener('result', event => this.onRecognizing(formObj, event));
	}

	onSpeechRecognizeStart(formObj) {
		formObj.$searchSpeechButton.addClass('is-recording');
	}

	onSpeechRecognizeEnd(formObj, event) {
		formObj.$searchSpeechButton.removeClass('is-recording');

		this.setBlurState(formObj);
		this.checkAndSearch(formObj, event);
	}

	onRecognizing(formObj, event) {
		const transcript = Array.from(event.results)
			.map(result => result[0])
			.map(result => result.transcript)
			.join('');

		this.onInputTyping(formObj);

		formObj.$searchInput.val(transcript);
	}

	startSpeechRecognize(formObj, recognition) {
		if (formObj.$searchInput && formObj.$searchInput.length) {
			formObj.$searchInput.val('');
		}

		this.setFocusState(formObj)
			.then(() => {
				recognition.start();
			});
	}

	onDocumentListener(formObj, event) {
		// скрытие формы с результатами поиска при расфокусировке из поля ввода
		if (!$(event.target).closest(formObj.$form).length) {
			this.closeInput(formObj);
			this.disableTypingListening(formObj);
			this.hideSearchResult(formObj);
		}
	}
}

module.exports = CSearchForm;

module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'excel-export/base' && templateVars.config ?
				templateVars.config :
				{
	"noHideInZoom": false,
	"downloadLink": {
		"tables": "/download/${site}/${lang}/partedTables/${pathTail}/${namePrefix}${xlsName}.xlsx",
		"charts": "/download/${site}/${lang}/partedChartsTables/${pathTail}/${namePrefix}${xlsName}.xlsx"
	},
	"namePrefix": {
		"tables": "t_",
		"charts": "c_"
	}
};
/**
 * Класс реализует базовую логику для компонента Accordions
 */
class CAccordions {
	constructor() {

	}
}

module.exports = CAccordions;

/**
 * Класс реализует базовую логику для компонента Projects
 */
class CProjects {
	constructor() {

	}

	//подключение shuffle
	async getShuffleModule(callback) {
		const lib = await import(/* webpackChunkName: "shufflejs" */ 'shufflejs');
		return AR.getEsm(lib);
	}
}

module.exports = CProjects;

import {checkAndSearch} from '../../index.js';
import {setErrorState} from '../../index.js';
import {unsetErrorState} from '../../index.js';
const $search = $('.search.search--result');

const config = require('./config.yaml');
const CSearchForm = require('../../index.js');

class CSearchForm_result extends CSearchForm {
	constructor() {
		super();
		this.config = config;
	}

	init() {
		this.$searchForm = $('.js-search--result');

		if (this.$searchForm.length) {
			this.$searchForm.each((i, elem) => {
				const $form = $(elem);
				const formObj = {
					$form: $form,
					$searchInput: $form.find('.js-search-input'),
					$searchMessage: $form.find('.js-search-message')
				};

				if (this.config.speechRecognition && $('html').hasClass('feature-speechrecognition')) {
					formObj.$searchSpeechButton = formObj.$form.find('.js-search-button-speech');

					this.initSpeechRecognition(formObj);
				}

				formObj.$searchInput.on('blur', $.proxy(this, 'onBlurInput', formObj));

				formObj.$form.on('submit', $.proxy(this, 'onFormSubmit', formObj));
			});
		}
	}

	onBlurInput(formObj, event) {
		this.setBlurState(formObj, event);
	}

	setBlurState(formObj, event) {
		const inputText = formObj.$searchInput.val();

		if (inputText.length) {
			formObj.$searchInput.val(this.trimText(inputText));
		}
	}

	onInputTyping(formObj, event) {
		super.onInputTyping(formObj, event);
	}

	showMessage(formObj) {
		super.showMessage(formObj);
		$(document).on('click', $.proxy(this, 'onDocumentListenerMessage', formObj));
	}

	hideMessage(formObj) {
		super.hideMessage(formObj);
		$(document).off('click', $.proxy(this, 'onDocumentListenerMessage'));
	}

	onDocumentListenerMessage(formObj, event) {
		if (!$(event.target).closest(formObj.$form).length) {
			this.hideMessage(formObj);
		}
	}
}

AR.waitComponents([], () => {
	const cSearchForm_result = new CSearchForm_result();

	cSearchForm_result.init();

	AR.pushComponent(cSearchForm_result, 'cSearchForm_result');
});

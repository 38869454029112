module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'popup/base' && templateVars.config ?
				templateVars.config :
				{
	"submitHandlerUrl": "${rootUrl}/contact/feedback",
	"cleanOnClose": true,
	"smartPlaceholder": true,
	"modalShowEffect": "transition.bounceDownIn",
	"modalShowSpeed": 500,
	"modalHideEffect": "transition.bounceUpOut",
	"modalHideSpeed": 500,
	"modalLockerOpacityShow": 0.5,
	"modalAnimation": "slide-in-right",
	"modalBlur": false
};
import 'particles.js';

class CWine24 {
	constructor() {

	}

	init() {
		particlesJS('particles-js', {
			particles: {
				number: {
					value: 185,
				},
				color: {
					value: '#ffffff'
				},
				shape: {
					type: 'circle',
				},
				opacity: {
					value: 0.8,
					random: true,
					anim: {
						enable: true,
						speed: 1,
						opacity_min: 0.1,
						sync: false
					}
				},
				size: {
					value: 6,
					random: true,
					anim: {
						enable: false,
						speed: 40,
						size_min: 0.1,
						sync: false
					}
				},
				line_linked: {
					enable: false,
				},
				move: {
					enable: true,
					speed: 3,
					direction: 'bottom',
					random: false,
					straight: false,
					out_mode: 'out',
					bounce: false,
					attract: {
						enable: false,
						rotateX: 600,
						rotateY: 1200
					}
				}
			},
			interactivity: {
				detect_on: 'canvas',
				events: {
					onhover: {
						enable: true,
						mode: 'bubble'
					},
					onclick: {
						enable: true,
						mode: 'push'
					},
					resize: true
				},
				modes: {
					grab: {
						distance: 400,
						line_linked: {
							opacity: 0.5
						}
					},
					bubble: {
						distance: 400,
						size: 4,
						duration: 0.3,
						opacity: 1,
						speed: 3
					},
					repulse: {
						distance: 200,
						duration: 0.4
					},
					push: {
						particles_nb: 4
					},
					remove: {
						particles_nb: 2
					}
				}
			},
			retina_detect: true
		});
	}
}


AR.waitComponents([], () => {
	const wine24Page = document.querySelector('.body--wine-24');

	if (wine24Page) {
		const cWine24 = new CWine24;
		cWine24.init();
		AR.pushComponent(cWine24, 'cWine24');
	}
});

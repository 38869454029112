/**
 * Класс реализует логику варианта base для компонента graphic
 */
class CGraphic_base {
	constructor() {
		this.$graphics = null;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.$graphics = $('.graphic');

		this.initGraphics(this.$graphics);
	}

	/**
	 * Инициализация графиков (добавить кнопки)
	 * @param {object} $graphics графики
	 */
	initGraphics($graphics) {
		$graphics.each((index, el) => {
			const $this = $(el);

			if (!$this.hasClass('icon')) {
				if (!$this.find('.figure-buttons.js-buttons').length) {
					$this.append('<div class="figure-buttons js-buttons"></div>');
				}

				if (!$this.hasClass('graphic--no-zoom')) {
					AR.components.cZoomIn_base.addZoomIn($this);
				}
			}
		});
	}
}

AR.waitComponents(['cZoomIn_base'], () => {
	const cGraphic_base = new CGraphic_base();
	// Вызов метода со всеми событиями
	cGraphic_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cGraphic_base, 'cGraphic_base');
});

/**
 * Класс реализует базовую логику для компонента captcha
 */
class CCaptcha {
	constructor() {
		this.initValidator();
	}

	initValidator() {
		if (!window.Parsley.hasValidator('captcha')) {
			window.Parsley
				.addValidator('captcha', {
					requirementType: 'boolean',
					validateString: function (value, requirement) {
						return requirement;
					}
				});
		}
	}
}

module.exports = CCaptcha;

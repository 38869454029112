const main = $(`.js-slider-wrapper--news`);

if (main.length) {
	const waiting = Promise.all([
		new Promise((res, rej) => {
			document.addEventListener('DOMContentLoaded', () => {
				res();
			}, false);
		}),
		new Promise((res, rej) => {
			AR.events.on('svgRenderedAll', $elements => {
				res($elements);
			});
		})
	]);

	AR.waitComponents(['cPreloader_base'], () => {
		init(main, waiting);
	});
}

async function init(mainpage, resources) {
	const {CSlider_news} = await import(/* webpackChunkName: "CSlider_news" */ './main.js');
	const cSlider_news = new CSlider_news(mainpage);
	cSlider_news.init(resources);

	AR.pushComponent(cSlider_news, 'cSlider_news');
}

// const isElementInView = require('component/core/.variant/base/additionals/isElementInView.js');

/**
 * Класс реализует базовую логику для компонента logo
 */
class CWine {
	constructor() {
		this.isIe = false;
	}

	init() {
		if (!document.querySelector('.body--wine')) {
			return false;
		}

		this.isIe = document.querySelector('.browser-ie') ? true : false;
		if (!this.isIe) {
			AR.events.on('svgRenderedAll', () => {
				this.initAnimatedTitles();
			});
		}

		// window.beforeunload = () => {
		// 	window.scrollTo(0, 0);
		// }
	}

	initAnimatedTitles() {
		this.titles = [...document.querySelectorAll('.js-animated-title')];

		if (this.titles.length) {

			const observer = new IntersectionObserver((entries, observer) => {

				entries.forEach(entry => {
					if (entry.isIntersecting && !entry.target.classList.contains('is-visible')) {
						entry.target.classList.add('is-visible');

						observer.unobserve(entry.target);
					}
				});

			}, {
				root: null,
				rootMargin: '50px',
				threshold: 0.3
			});


			this.titles.forEach(title => {
				this._setRandomPosition([...title.querySelectorAll('span')]);

				observer.observe(title);
			});

		}
	}

	_setRandomPosition(collection) {
		if (!collection.length) {
			return false;
		}

		collection.forEach(span => {
			span.style.transform = `translate(${randomInteger()}px, ${randomInteger()}px)`;
		});

		// TODO: добавить коэффициент для мобилок
		function randomInteger(min = -100, max = 100) {
			// случайное число от min до (max+1)
			let rand = min + Math.random() * (max + 1 - min);
			return Math.floor(rand);
		}
	}
}

const cWine = new CWine;

// document.addEventListener('DOMContentLoaded', () => {
// 	cWine.init();
// });

AR.pushComponent(cWine, 'cWine');

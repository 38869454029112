const main = $(`.js-slider-wrapper--main`);

if (main.length) {
	const waiting = Promise.all([
		new Promise((res, rej) => {
			document.addEventListener('DOMContentLoaded', () => {
				res();
			}, false);
		}),
		new Promise((res, rej) => {
			AR.events.on('svgRenderedAll', $elements => {
				res($elements);
			});
		})
	]);

	AR.waitComponents(['cPreloader_base'], () => {
		init(main, waiting);
	});
}

async function init(mainpage, resources) {
	const {CSlider_main} = await import(/* webpackChunkName: "CSlider_main" */ './main.js');
	const cSlider_main = new CSlider_main(mainpage);
	cSlider_main.init(resources);

	AR.pushComponent(cSlider_main, 'cSlider_main');
}

const {throttle} = require('throttle-debounce');
// const Tools = require('./Tools.js');

class CSlider {
	constructor() {
		this.subSliders = {};
	}

	async initSlider($sliderWrapper, customConfig = {}) {
		this.$sliderWrapper = $sliderWrapper;
		this.$slider = this.$sliderWrapper.find('.js-slider');

		if (this.$slider.length) {
			this.CSwiper = await this.getSwiper();
			this.Tools = await this.getTools();
			this.Tools.disableTab(this.$slider.find('*'));
			this.ieFixes();

			this.swiper = new this.CSwiper(this.$slider, this.getConfig(customConfig, this.$slider));
		}
	}

	async getTools() {
		const module = await import(/* webpackChunkName: "swiperTools" */ './Tools.js');
		return AR.getEsm(module);
	}

	async getSwiper() {
		const lib = await import(/* webpackChunkName: "swiper" */ 'swiper/js/swiper.js');
		return AR.getEsm(lib);
	}

	initSubSlider($sliderWrapper, sliderID = 0, customConfig = {}) {
		const $slider = $sliderWrapper.find('.js-sub-slider');

		if ($slider.length) {
			this.subSliders[sliderID] = new this.CSwiper($slider, this.getConfig(customConfig, $slider));
		}
	}

	getConfig(customConfig, $slider) {
		const defaultConfig = {
			direction: this.config.options['direction'],
			autoplay: this.config.options['autoplay'],
			initialSlide: this.config.options['initialSlide'],
			speed: this.config.options['speed'] || 1000,
			mousewheel: this.config.options['mousewheel'],
			keyboard: this.config.options['keyboard'],
			simulateTouch: this.config.options['simulateTouch'],
			pagination: this.getPagination(),
			navigation: this.config.options['navigation'],
			loop: this.config.options['loop'],
			hashNavigation: this.config.options['hashNavigation'],
			nested: this.config.options['nested'],
			runCallbacksOnInit: this.config.options['runCallbacksOnInit'],
			autoplayDisableOnInteraction: this.config.options['autoplayDisableOnInteraction'],
			// fadeEffect: this.config.options['fadeEffect'],
			// effect: this.config.options['effect'],
			preventInteractionOnTransition: this.config.options['preventInteractionOnTransition'],
			parallax: this.config.options['parallax'],
			followFinger: this.config.options['followFinger'] || false,
			on: {
				slideChangeTransitionStart: () => {
					AR.events.emit('onSlideChangeTransitionStart', $slider);
					this.onSlideChangeTransitionStart();
					AR.events.emit('onSlideChangeTransitionStart_general');
				},
				slideChangeTransitionEnd: () => {
					AR.events.emit('onSlideChangeTransitionEnd', $slider);
					this.onSlideChangeTransitionEnd();
				},
				autoplay: () => {
					AR.events.emit('onAutoplay', $slider);
					this.onAutoplay();
				},
				autoplayStart: () => {
					AR.events.emit('onAutoplayStart', $slider);
					this.onAutoplayStart();
				},
				autoplayStop: () => {
					AR.events.emit('onAutoplayStop', $slider);
					this.onAutoplayStop();
				},
				init: () => {
					AR.events.emit('onInit', $slider);
					this.onInit();
				},
				fromEdge: () => {
					AR.events.emit('fromEdge', $slider);
					this.onFromEdge();
				},
				reachBeginning: () => {
					AR.events.emit('reachBeginning', $slider);
					this.onReachBeginning();
				},
				reachEnd: () => {
					AR.events.emit('reachEnd', $slider);
					this.onReachEnd();
				},
				slideChange: () => {
					AR.events.emit('onSlideChange', $slider);
				}
			}
		};
		const newConfig = $.extend(defaultConfig, customConfig);

		return newConfig;
	}

	onInit() {
		// if ($('html').hasClass('feature-no-flexbox') || $('html').hasClass('browser-safari-10') || $('html').hasClass('browser-safari-9')) {
		// 	this.fixContainerHeight();
		// }

		AR.components.cPreloader_base.disablePreloaderInItem(this.$sliderWrapper);
	}

	onSlideChangeTransitionStart() {
	}

	onSlideChangeTransitionEnd() {
	}

	onFromEdge() {
	}

	onReachBeginning() {
	}

	onReachEnd() {
	}

	onAutoplay() {
	}

	onAutoplayStart() {
	}

	onAutoplayStop() {
	}

	getPagination(config = this.config.options) {
		const paginationObj = $.extend({}, config['pagination']);

		if (paginationObj) {
			if (paginationObj.render) {
				paginationObj['renderBullet'] = (index, className) => {
					return this.getBulletMarkup(index, className);
				};
			}

			return paginationObj;
		} else {
			return false;
		}
	}

	getSubSliderPagination(config) {
		const paginationObj = $.extend({}, config['pagination']);

		if (paginationObj) {
			if (paginationObj.render) {
				paginationObj['renderBullet'] = (index, className) => {
					return this.getSubSliderBulletMarkup(index, className);
				};
			}

			return paginationObj;
		} else {
			return false;
		}
	}

	getBulletMarkup(index, className) {
		return `<span class="${className} b-slider-pagination__item">${index + 1}</span>`;
	}

	getSubSliderBulletMarkup(index, className) {
		return `<span class="${className} b-sub-slider-pagination__item"></span>`;
	}

	fixContainerHeight() {
		const $header = $('.l-header');
		const $footer = $('.l-footer');
		const $container = $('.l-page, .content-area');
		const $window = $(window);
		const minHeight = this.config['containerMinHeight'];
		const resizeHandler = function () {
			const contentAreaHeight = $window.height() - $header.outerHeight() - $footer.outerHeight();

			if ((typeof minHeight == 'number' && contentAreaHeight > minHeight) || minHeight == 'auto') {
				$container.height(contentAreaHeight);
			}
		};

		resizeHandler();

		$(window).resize(throttle(150, () => {
			resizeHandler();
		}));
	}

	/**
	 * Возвращает ID слайда по его хешу.
	 * @param addressHash - целевой хэш
	 * @returns {int} | {boolean}
	 */
	getSlideIDByHash(addressHash) {
		if (!addressHash) {
			return false;
		}


		let $targetSlide = $(Array.from(this.swiper.slides).filter((item, key) => {
			let slideHash = $(item).attr(`data-slide-hash`);

			return (slideHash == addressHash)
				? true
				: (slideHash == '#' + addressHash);
		}).shift());

		return ($targetSlide.length) ? $targetSlide.attr('data-slide-id') : false;
	}

	getSubSlideIDByHash(addressHash, mainSliderID) {
		if (!mainSliderID || !addressHash) {
			return false;
		}

		let $targetSlide = $(Array.from(this.subSliders[mainSliderID].slides).filter((item, key) => {
			let slideHash = $(item).attr(`data-slide-hash`);

			return (slideHash == addressHash)
				? true
				: (slideHash == '#' + addressHash);
		}).shift());

		return ($targetSlide.length) ? $targetSlide.attr('data-slide-id') : false;
	}

	/**
	 * Возвращает хэш элемента по его ID
	 * @param id - id элемента
	 * @returns {int} | {boolean}
	 */
	getHashBySlideID(id) {
		let $targetSlide = this.sliderItems.filter((item, key) => {
			return $(item).attr(`data-slide-id`) == id;
		});

		return ($targetSlide.length) ? $targetSlide.attr('data-slide-hash') : false;
	}

	/**
	 * Возвращает хеш глобального слайда + хеш текущего элемента из интерактивного содержимого
	 * @param mainSlideID - id основного элемента слайда
	 * @param subSlideID - id слайда в слайде
	 */
	getFullHashBySlideID(mainSlideID, subSlideID) {
		let $targetSlide = $(Array.from(this.swiper.slides).filter((item, key) => $(item).attr(`data-slide-id`) == mainSlideID).shift());

		if ($targetSlide.length) {
			let mainSlideHash = $targetSlide.attr('data-slide-hash');
			let interactiveItemHash = '';

			let $subSlider = $targetSlide.find('.js-sub-slider');
			if ($subSlider.length) {
				interactiveItemHash = this.getHashBySubSlideID((typeof (subSlideID) != 'undefined' ? subSlideID : 0), mainSlideID);
			}

			if (interactiveItemHash) {
				return this.Tools.jointHashByDelimiter([mainSlideHash, interactiveItemHash]);
			} else {
				return mainSlideHash;
			}
		} else {
			return false;
		}
	}

	getHashBySubSlideID(subSlideID, mainSlideID) {
		if (typeof subSlideID != 'undefined' && typeof mainSlideID != 'undefined') {
			const subSlider = this.subSliders[mainSlideID];

			if (subSlider) {
				let $targetSlide = $(Array.from(subSlider.slides).filter((item, key) => $(item).attr(`data-slide-id`) == subSlideID).shift());

				return ($targetSlide.length) ? $targetSlide.attr('data-slide-hash') : false;
			}
		} else {
			return false;
		}
	}

	/**
	 * Переход к основному слайд и переключение его внутреннего содержимого на нужны элемент. Адреса переключения
	 * задаются в параметре
	 * @param arHash - массив параметров для перехода. Под первым ключем - основной слайд. Под вторым - внутренний.
	 */
	goToSlideByHash(arHash = []) {
		//получение ID слада по хешу
		let hashSlideID = this.getSlideIDByHash(arHash[0]);
		this.swiper.slideTo(hashSlideID, 0);

		//определяем и переключаем второй слайд (если задан)
		if (typeof arHash[1] != 'undefined') {
			this.goToSubSlideByHash(arHash[1], hashSlideID);
		}
	}

	goToSubSlideByHash(hash, mainSlideID) {
		if (hash && mainSlideID) {
			//получение ID слада по хешу
			let hashSlideID = this.getSubSlideIDByHash(hash, mainSlideID);

			this.subSliders[mainSlideID].slideTo(hashSlideID, 0);
		}
	}

	ieFixes() {
		Math.sign = Math.sign || function (x) {
			x = Number(x);
			if (x === 0 || isNaN(x)) {
				return x;
			}
			return x > 0 ? 1 : -1;
		};
	}


	getSwiperState() {
		let obj = {};

		if (this.config.name === 'main') {

			obj.current = parseInt(this.swiper.realIndex);
			obj.total = this.swiper.slides.length;
			obj.begin = this.swiper.isBeginning;
			obj.end = this.swiper.isEnd;

		} else { // this.config.name === 'mainpage'
			let id = this.config.subSliders[0].parentSlideID; // первый (и едиственный) сабслайдер

			obj.total = this.swiper.slides.length + this.subSliders[id].slides.length - 1; // || this.config.simulatedPagination.itemsCount
			obj.begin = this.swiper.isBeginning;
			obj.end = this.swiper.isEnd;

			switch (true) {
				case this.swiper.realIndex < id:
					obj.current = parseInt(this.swiper.realIndex);
					break;

				case this.swiper.realIndex == id:
					obj.current = parseInt(this.subSliders[id].realIndex + id);
					break;

				case this.swiper.realIndex > id:
					obj.current = parseInt(this.swiper.realIndex + this.subSliders[id].slides.length - 1);
					break;
			}

		}

		return obj;
	}

	/**
	 * Прогрессбар автоплея вынесен в общие методы
	 * принимает:
	 * @param targetCircle - нода (svg путь).
	 * @param setDuration - длительность анимации в мс.
	 */
	initAutoplayIndicator(targetCircle, setDuration = false) {
		if (!targetCircle) {
			return false;
		}
		const radius = targetCircle.r.baseVal.value;
		const circumference = 2 * Math.PI * radius;

		targetCircle.style.strokeDasharray = `${circumference} ${circumference}`;

		const duration = setDuration ? setDuration : this.config.options.autoplay ? this.config.options.autoplay.delay : false;

		const _setProgress = (darationTime) => {
			if (!darationTime) {
				return false;
			}

			$(targetCircle).velocity('stop');

			$.Velocity.hook($(targetCircle), 'strokeDashoffset', `-${circumference}px`);

			$(targetCircle).velocity({
				strokeDashoffset: '0px'
			}, {
				duration: darationTime * 1.2
			});
		};

		_setProgress(duration);

		AR.events.on('onSlideChangeTransitionStart_general', () => {
			if (this.getSwiperState().end) {
				_setProgress(0.1);
			} else {
				if (!this.swiper.autoplay.running) {
					this.swiper.autoplay.start();
				}

				_setProgress(duration);
			}
		});

		window.onblur = () => {
			$(targetCircle).velocity('stop');
		};

		window.onfocus = () => {
			if (this.getSwiperState().end) {
				_setProgress(0.0001);
			} else {
				if (!this.swiper.autoplay.running) {
					this.swiper.autoplay.start();
				}

				_setProgress(duration);
			}
		};
	}

	initProgressBar(pagination) {
		if (!pagination.length) {
			return false;
		}

		const bar = pagination[0].querySelector('.js-progress-bar');
		const item = pagination[0].querySelector('.js-set-pagination-size');

		// параметры ползунка
		let swiperState = this.getSwiperState();
		let w_height = pagination[0].offsetHeight;
		let e_height = item.offsetHeight;
		let empty = (w_height - (e_height * swiperState.total)) / (swiperState.total - 1);

		const setPaginationPos = (duration = 500) => {
			$(bar)
				.velocity('stop', true)
				.velocity({
					translateY: (empty + e_height) * this.getSwiperState().current,
				}, duration);
		};

		AR.events.on('onSlideChangeTransitionStart_general', () => setPaginationPos());

		// TODO: hash-навигация
		$(bar).velocity({
			translateY: (empty + e_height) * swiperState.current
		}, 50);

		$(window).on('resize', throttle(150, () => {
			w_height = pagination[0].offsetHeight;
			e_height = item.offsetHeight;
			empty = (w_height - (e_height * swiperState.total)) / (swiperState.total - 1);

			setPaginationPos(0);
		}));

		return true;
	}
}

module.exports = CSlider;

module.exports = function anonymous(locals, filters, escape, rethrow) {
    escape = escape || function(html) {
        return String(html).replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/'/g, "&#39;").replace(/"/g, "&quot;");
    };
    var __stack = {
        lineno: 1,
        input: '<div class="col-4 col--no-gutter col-sm-6 col-xs-12 col-news">\n    <% if (newsRec.link && newsRec.link.length) { %>\n    <a href="<%= newsRec.link %>" class="news__link">\n        <% getInner(newsRec); %>\n    </a>\n    <% } else { %>\n    <div class="news__link news__link--static">\n        <% getInner(newsRec); %>\n    </div>\n    <% } %>\n</div>\n\n<% function getInner(rec) { %>\n<div class="news-block">\n    <div class="news-block__image" style="background-image: url(<%- rec.image %>);">\n    </div>\n    <div class="news-block__category news-block__category--<%- rec.colorCategory %>">\n        <%- rec.category %>\n    </div>\n    <div class="news-block__content">\n        <div class="news-block__date">\n            <%- rec.date %>\n        </div>\n        <div class="news-block__text">\n            <%- rec.text %>\n        </div>\n    </div>\n</div>\n<% } %>',
        filename: "."
    };
    function rethrow(err, str, filename, lineno) {
        var lines = str.split("\n"), start = Math.max(lineno - 3, 0), end = Math.min(lines.length, lineno + 3);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    }
    try {
        var buf = [];
        with (locals || {}) {
            (function() {
                buf.push('<div class="col-4 col--no-gutter col-sm-6 col-xs-12 col-news">\n    ');
                __stack.lineno = 2;
                if (newsRec.link && newsRec.link.length) {
                    buf.push('\n    <a href="', escape((__stack.lineno = 3, newsRec.link)), '" class="news__link">\n        ');
                    __stack.lineno = 4;
                    getInner(newsRec);
                    buf.push("\n    </a>\n    ");
                    __stack.lineno = 6;
                } else {
                    buf.push('\n    <div class="news__link news__link--static">\n        ');
                    __stack.lineno = 8;
                    getInner(newsRec);
                    buf.push("\n    </div>\n    ");
                    __stack.lineno = 10;
                }
                buf.push("\n</div>\n\n");
                __stack.lineno = 13;
                function getInner(rec) {
                    buf.push('\n<div class="news-block">\n    <div class="news-block__image" style="background-image: url(', (__stack.lineno = 15, rec.image), ');">\n    </div>\n    <div class="news-block__category news-block__category--', (__stack.lineno = 17, rec.colorCategory), '">\n        ', (__stack.lineno = 18, rec.category), '\n    </div>\n    <div class="news-block__content">\n        <div class="news-block__date">\n            ', (__stack.lineno = 22, rec.date), '\n        </div>\n        <div class="news-block__text">\n            ', (__stack.lineno = 25, rec.text), "\n        </div>\n    </div>\n</div>\n");
                    __stack.lineno = 29;
                }
                buf.push("");
            })();
        }
        return buf.join("");
    } catch (err) {
        rethrow(err, __stack.input, __stack.filename, __stack.lineno);
    }
}
const newsRecTemplate = require('./recTemplate.ejs');
const text = require('./text.json')[templateVars.lang];
const config = require('./config.yaml');
const $news = $('.js-news');

if ($news.length) {
	const $preloader = $news.find('.js-news-preloader');
	let indexFrom = config.newsInitialCount;
	let isLast = false;
	// undefined
	let loadingInProcess = false;

	function addArticleCloseBtn() {
		$('.news__article:not(:has(>.wrapper-article-close))')
			.append(`<div class="wrapper-article-close">
						<div class="article-close">
							<span>${text.slideUp}</span>
						</div>
					</div>`);
	}

	function loadNews(indexFrom) {
		return new Promise((resolve, reject) => {
			$.getJSON('/loadNews', {
				indexFrom: indexFrom,
				newsCount: config.newsLoadCount,
				lang: templateVars.lang,
			})
				.done(resolve)
				.fail(reject);

		});
	}

	function renderNews(news) {
		return news.map(newsRec => {
			return newsRecTemplate({
				newsRec,
				lib: {moment: global.moment},
				siteUrl: templateVars.siteUrl,
				text
			});
		});
	}

	function showMoreNews() {

		showPreloader();
		loadingInProcess = true;

		loadNews(indexFrom)
			.then(response => {
				const news = response.news;
				let renderedNews = renderNews(news);
				isLast = response.isLast;

				$('.news').find('.row').append(renderedNews);

				addArticleCloseBtn();

				indexFrom += config.newsLoadCount;

				hidePreloader();

				loadingInProcess = false;
			})
			.catch(err => {
				console.error(err);
				loadingInProcess = false;
			});
	}

	function showPreloader() {
		$preloader.show();
	}

	function hidePreloader() {
		$preloader.hide();
	}

	// изоляция функционала для 1-ой конкретной стр. со всеми новостями
	// класс и условие можно измениять, расширять
	if (document.querySelector('.body--news-all')) {
		$(window).scroll(function () {
			// on mobile (window.visualViewport.height) != $(window).height()
			let correctionValue = $(window).width() <= 798 ? 100 : 0;
			//
			if (Math.ceil($(window).scrollTop() + $(window).height() + correctionValue) >= $(document).height() && !isLast && !loadingInProcess) {
				showMoreNews();
			}
		});
	}

	addArticleCloseBtn();
}

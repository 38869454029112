const isElementInView = require('component/core/.variant/base/additionals/isElementInView.js');
const CAnimations = require('../../index.js');
const config = require('./config.yaml');
const {throttle} = require('throttle-debounce');

/**
 * Реализует логику варианта blocks компонента animations
 */
class CAnimations_blocks extends CAnimations {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.initAnimations();
	}

	/**
	 * Инициализировать анимации
	 * @param {string} selector селектор элементов анимации
	 */
	initAnimations(selector = '[data-animation="true"]') {
		if ($(`${selector}:not(.animate):not(.animated)`).length) {
			this.makeItAnimate(selector);
			this.watchForAnimate();
			$(window).on('scroll', $.proxy(this, 'onThrottle'));
		}
	}

	/**
	 * Ограничение количества вызовов на событие скрола
	 */
	onThrottle() {
		throttle(100, this.onScroll());
	}

	/**
	 * Callback на событие скролла
	 */
	onScroll() {
		this.watchForAnimate();
	}

	/**
	 * Анимировать элементы в области видимости окна
	 * @param {string} selector селектор элементов анимации
	 */
	makeItAnimate(selector) {
		const $animateElements = $(`${selector}:not(.animate):not(.animated)`);

		$animateElements.addClass('animate');
	}

	/**
	 * Наблюдать за областью видимости окна для анимирования
	 */
	watchForAnimate() {
		const $animateElements = $('.animate');

		if ($animateElements.length) {
			$animateElements.each((i, elem) => {
				const $elem = $(elem);
				const data = $elem.data();
				const force = data['animationForce'] ? true : false;

				if (force) {
					this.animate($elem, data);
				} else if (isElementInView($elem)) {
					this.animate($elem, data);
				}
			});
		} else {
			$(window).off('scroll', $.proxy(this, 'onScroll'));
		}
	}

	/**
	 * Анимирование элементов
	 * @param {object} $elem элемент анимации
	 * @param {object} options опции анимирования
	 */
	animate($elem, options) {
		const delay = options['animationDelay'] || 0;
		const duration = options['animationDuration'] || this.config.animationsDuration || false;
		const loop = options['animationLoop'] ? 'infinite' : '';

		setTimeout(() => {
			$elem
				.removeClass('animate')
				.addClass(`animated ${loop}`)
				.css({
					'-webkit-animation-duration': `${duration}ms`,
					'animation-duration': `${duration}ms`
				});
		}, delay);
	}
}

AR.waitComponents([], () => {
	const cAnimations_blocks = new CAnimations_blocks();
	// Вызов метода, инициализирующего все существующие события
	cAnimations_blocks.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cAnimations_blocks, 'cAnimations_blocks');
});

const tooltips = $('.b-tooltip');

if (tooltips.length) {
	const waiting = Promise.all([
		new Promise((res, rej) => {
			document.addEventListener('DOMContentLoaded', () => {
				res();
			}, false);
		}),
		new Promise((res, rej) => {
			AR.events.on('svgRenderedAll', $elements => {
				res($elements);
			});
		})
	]);

	AR.waitComponents(['cPreloader_base'], () => {
		init(tooltips, waiting);
	});
}

async function init(parent, resources) {
	const {CTooltips_base} = await import(/* webpackChunkName: "CTooltips_base" */ './main.js');
	const cTooltips_base = new CTooltips_base(parent);
	cTooltips_base.init(resources);

	AR.pushComponent(cTooltips_base, 'cTooltips_base');
}

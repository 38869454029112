module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'buttons-panel/in-column' && templateVars.config ?
				templateVars.config :
				{
	"modifier": "in-column",
	"ifEmptyPanel": "remove",
	"animation": {
		"list": {
			"show": {
				"effect": "transition.slideLeftIn",
				"duration": 200
			},
			"hide": {
				"effect": "transition.slideLeftOut",
				"duration": 200
			}
		},
		"sublist": {
			"show": {
				"effect": "slideDown",
				"duration": 200
			},
			"hide": {
				"effect": "slideUp",
				"duration": 200
			}
		}
	},
	"events": {
		"list": {
			"click": {
				"show": "click touchstart",
				"hide": "click touchstart"
			},
			"hover": {
				"show": "touchstart mouseenter",
				"hide": "mouseover touchstart"
			}
		},
		"sublist": {
			"click": {
				"show": "click touchstart",
				"hide": "click touchstart"
			},
			"hover": {
				"show": "touchstart mouseenter",
				"hide": "mouseover touchstart"
			}
		}
	},
	"hoverablePanel": false,
	"hoverableSublist": false,
	"tools": {
		"panelType": "compact",
		"title": {
			"ru": "Инструменты",
			"en": "Tools"
		},
		"sublistSlideType": "bottom",
		"InMyReport": false,
		"buttonWithText": false,
		"offlineHideButtons": [
			"offline",
			"feedback",
			"share",
			"social"
		],
		"buttonsPanelHideButtons": [
			"siteMap",
			"addToReport",
			"delFromReport",
			"offline",
			"questionary",
			"social",
			"ios",
			"android",
			"mobileApp",
			"printVersion",
			"companyOnMap",
			"contacts",
			"xls",
			"glossary",
			"disclaimer",
			"archive",
			"applications",
			"zip"
		],
		"hideButtonsOnSystem": [
			"addToReport",
			"delFromReport",
			"printVersion",
			"pdf",
			"share"
		],
		"hideButtonsOnMainPage": [
			"addToReport",
			"delFromReport",
			"printVersion",
			"pdf"
		],
		"hideCurrentButtonOnPage": true,
		"showTour": false,
		"sorting": {
			"ru": [
				"addToReport",
				"delFromReport",
				"myReport",
				"printVersion",
				"pdf",
				"share",
				"history",
				"feedback",
				"download",
				"analysis",
				"compare",
				"offline",
				"questionary",
				"social",
				"ios",
				"android",
				"mobileApp",
				"companyOnMap",
				"siteMap",
				"contacts",
				"xls",
				"glossary",
				"disclaimer",
				"archive",
				"see",
				"applications",
				"zip"
			],
			"en": [
				"addToReport",
				"delFromReport",
				"myReport",
				"printVersion",
				"pdf",
				"share",
				"history",
				"feedback",
				"download",
				"analysis",
				"compare",
				"offline",
				"questionary",
				"social",
				"ios",
				"android",
				"mobileApp",
				"companyOnMap",
				"siteMap",
				"contacts",
				"xls",
				"glossary",
				"disclaimer",
				"archive"
			]
		},
		"links": [
			{
				"id": "myReport",
				"modifier": "myreport",
				"targetBlank": false,
				"url": "${rootUrl}/my-report",
				"text": {
					"ru": "Мой отчет",
					"en": "My report"
				},
				"description": {
					"ru": "Страниц <br/>в отчете",
					"en": "Pages report"
				}
			},
			{
				"id": "addToReport",
				"modifier": "add js-add-page",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Добавить в «Мой отчет»",
					"en": "Add to My Report"
				}
			},
			{
				"id": "delFromReport",
				"modifier": "del js-del-page",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Удалить из «Моего отчета»",
					"en": "Delete from My Report"
				}
			},
			{
				"id": "printVersion",
				"modifier": "print js-print",
				"targetBlank": true,
				"url": "javascript:;",
				"text": {
					"ru": "Печать страницы",
					"en": "Print version"
				},
				"event": "window.print();"
			},
			{
				"id": "pdf",
				"modifier": "pdf js-get-pdf",
				"targetBlank": true,
				"url": "${pdf}",
				"text": {
					"ru": "Печать страницы",
					"en": "PDF version"
				},
				"description": {
					"ru": "PDF файл активного раздела для сохранения и скачивания",
					"en": "Saving and downloading PDF file"
				}
			},
			{
				"id": "share",
				"modifier": "share js-share",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Поделиться",
					"en": "Share"
				},
				"subList": [
					{
						"id": "shareVkontakte",
						"modifier": "vkontakte",
						"targetBlank": true,
						"url": "http://vkontakte.ru/share.php?url={{pageUrl}}&title={{pageTitle}}&image={{thisSiteUrl}}&description=",
						"text": {
							"ru": "Вконтакте",
							"en": "Vkontakte"
						}
					},
					{
						"id": "shareLinkedin",
						"modifier": "linkedin",
						"targetBlank": true,
						"url": "http://www.linkedin.com/shareArticle?mini=true&url={{pageUrl}}&title={{pageTitle}}&summary=&source={{thisSiteUrl}}",
						"text": {
							"ru": "Linkedin",
							"en": "Linkedin"
						}
					},
					{
						"id": "shareTwitter",
						"modifier": "twitter",
						"targetBlank": true,
						"url": "http://twitter.com/share?text={{pageTitle}}&url={{pageUrl}}",
						"text": {
							"ru": "Twitter",
							"en": "Twitter"
						}
					},
					{
						"id": "shareFacebook",
						"modifier": "facebook",
						"targetBlank": true,
						"url": "https://www.facebook.com/sharer/sharer.php?u={{pageUrl}}&t={{pageTitle}}",
						"text": {
							"ru": "Facebook",
							"en": "Facebook"
						}
					},
					{
						"id": "shareGoogleplus",
						"modifier": "googleplus",
						"targetBlank": true,
						"url": "https://plus.google.com/share?url={{pageUrl}}",
						"text": {
							"ru": "Google+",
							"en": "Google+"
						}
					},
					{
						"id": "shareEmail",
						"modifier": "email js-share-form-button",
						"targetBlank": false,
						"url": "",
						"text": {
							"ru": "E-mail",
							"en": "E-mail"
						}
					}
				]
			},
			{
				"id": "history",
				"modifier": "history",
				"targetBlank": false,
				"url": "${rootUrl}/history",
				"text": {
					"ru": "История",
					"en": "Browsing history"
				},
				"description": {
					"ru": "Список посещенных страниц",
					"en": "List of Visited Pages"
				}
			},
			{
				"id": "feedback",
				"modifier": "feedback js-feedback-form-button",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Обратная связь",
					"en": "Feedback"
				},
				"description": {
					"ru": "Нам очень интересно ваше мнение",
					"en": "We are interested in your opinion"
				}
			},
			{
				"id": "download",
				"modifier": "download",
				"targetBlank": false,
				"url": "${rootUrl}/downloads",
				"text": {
					"ru": "Центр загрузки",
					"en": "Download center"
				},
				"description": {
					"ru": "Инструмент для скачивания отчетности",
					"en": "Download Reporting Tool"
				}
			},
			{
				"id": "analysis",
				"modifier": "analysis",
				"targetBlank": false,
				"url": "${rootUrl}/interactive-analysis",
				"text": {
					"ru": "Интерактивный анализ",
					"en": "Interactive analysis"
				},
				"description": {
					"ru": "Инструмент для анализа показателей отчетности",
					"en": "Indicator analysis Reporting Tool"
				}
			},
			{
				"id": "compare",
				"modifier": "compare",
				"targetBlank": true,
				"url": "${rootUrl}",
				"text": {
					"ru": {
						"compare": "Предыдущий отчет",
						"see": "Смотреть предыдущий отчет",
						"yearText": "",
						"compareList": "Предыдущие отчеты",
						"seeList": "Смотреть предыдущие отчеты",
						"previousReports": "Предыдущие отчеты"
					},
					"en": {
						"compare": "Previous report",
						"see": "See previous report",
						"yearText": "",
						"compareList": "Previous reports",
						"seeList": "See previous reports",
						"previousReports": "Previous reports"
					}
				}
			},
			{
				"id": "offline",
				"modifier": "offline",
				"targetBlank": true,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "Off-line версия",
					"en": "Off-line version"
				}
			},
			{
				"id": "questionary",
				"modifier": "questionary",
				"targetBlank": false,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "Анкета",
					"en": "Quetionnaire"
				}
			},
			{
				"id": "social",
				"modifier": "social js-social",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Компания в соц. сетях",
					"en": "The company in social"
				},
				"subList": [
					{
						"id": "socialVkontakte",
						"modifier": "vkontakte",
						"targetBlank": true,
						"url": "http://vkontakte.ru",
						"text": {
							"ru": "Вконтакте",
							"en": "Vkontakte"
						}
					},
					{
						"id": "socialLinkedin",
						"modifier": "linkedin",
						"targetBlank": true,
						"url": "http://www.linkedin.com",
						"text": {
							"ru": "Linkedin",
							"en": "Linkedin"
						}
					},
					{
						"id": "socialTwitter",
						"modifier": "twitter",
						"targetBlank": true,
						"url": "http://twitter.com",
						"text": {
							"ru": "Twitter",
							"en": "Twitter"
						}
					},
					{
						"id": "socialFacebook",
						"modifier": "facebook",
						"targetBlank": true,
						"url": "https://www.facebook.com",
						"text": {
							"ru": "Facebook",
							"en": "Facebook"
						}
					},
					{
						"id": "socialInstagram",
						"modifier": "instagram",
						"targetBlank": true,
						"url": "https://instagram.com",
						"text": {
							"ru": "Instagram",
							"en": "Instagram"
						}
					},
					{
						"id": "socialYoutube",
						"modifier": "youtube",
						"targetBlank": true,
						"url": "https://youtube.com",
						"text": {
							"ru": "YouTube",
							"en": "YouTube"
						}
					},
					{
						"id": "socialGoogleplus",
						"modifier": "googleplus",
						"targetBlank": true,
						"url": "https://plus.google.com",
						"text": {
							"ru": "Google+",
							"en": "Google+"
						}
					}
				]
			},
			{
				"id": "ios",
				"modifier": "ios",
				"targetBlank": true,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "Приложение для iOS",
					"en": "iOS app"
				}
			},
			{
				"id": "android",
				"modifier": "android",
				"targetBlank": true,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "Приложение для Android",
					"en": "Android app"
				}
			},
			{
				"id": "mobileApp",
				"modifier": "mobile-app",
				"targetBlank": true,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "Приложение для iOS и Android",
					"en": "Android and iOS apps"
				}
			},
			{
				"id": "companyOnMap",
				"modifier": "on-map",
				"targetBlank": true,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "Компания на карте",
					"en": "Company on the map"
				}
			},
			{
				"id": "siteMap",
				"modifier": "site-map",
				"targetBlank": true,
				"url": "${rootUrl}/sitemap",
				"text": {
					"ru": "Карта сайта",
					"en": "Sitemap"
				}
			},
			{
				"id": "contacts",
				"modifier": "contacts",
				"targetBlank": false,
				"url": "${rootUrl}/contacts/",
				"text": {
					"ru": "Контакты",
					"en": "Contacts"
				}
			},
			{
				"id": "xls",
				"modifier": "xls",
				"targetBlank": true,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "xls",
					"en": "xls"
				}
			},
			{
				"id": "glossary",
				"modifier": "glossary",
				"targetBlank": false,
				"url": "${rootUrl}/glossary/",
				"text": {
					"ru": "Глоссарий",
					"en": "Glossary"
				}
			},
			{
				"id": "archive",
				"modifier": "archive-reports",
				"targetBlank": false,
				"url": "${rootUrl}/archive/",
				"text": {
					"ru": "Предыдущие отчеты",
					"en": "Previous reports"
				}
			},
			{
				"id": "disclaimer",
				"modifier": "disclaimer",
				"targetBlank": false,
				"url": "${rootUrl}/disclaimer/",
				"text": {
					"ru": "Ограничение ответственности",
					"en": "Disclaimer"
				}
			}
		]
	},
	"materials": {
		"panelType": "compact",
		"title": {
			"ru": "Материалы",
			"en": "Materials"
		},
		"slideType": "vertical",
		"buttonWithText": false,
		"offlineHideButtons": [
			"offline"
		],
		"buttonsPanelHideButtons": [],
		"sorting": {
			"ru": [
				"ar",
				"ar2",
				"sustainability",
				"fr",
				"app",
				"journal",
				"offline"
			],
			"en": [
				"ar",
				"ar2",
				"sustainability",
				"fr",
				"app",
				"journal",
				"offline"
			]
		},
		"links": [
			{
				"id": "ar",
				"modifier": "ar",
				"targetBlank": true,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "Годовой Отчет 2012",
					"en": "Annual Report 2012"
				}
			},
			{
				"id": "ar2",
				"modifier": "ar",
				"targetBlank": true,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "Годовой Отчет 2013",
					"en": "Annual Report 2013"
				}
			},
			{
				"id": "sustainability",
				"modifier": "sustainability",
				"targetBlank": true,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "Отчет об устройчивом развитии",
					"en": "Sustainability Report"
				}
			},
			{
				"id": "fr",
				"modifier": "fr",
				"targetBlank": true,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "Финансовый Отчет 2012",
					"en": "Financial Report 2012"
				}
			},
			{
				"id": "app",
				"modifier": "app",
				"targetBlank": true,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "Приложения",
					"en": "Applications"
				}
			},
			{
				"id": "journal",
				"modifier": "journal",
				"targetBlank": true,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "Корпоративный журнал",
					"en": "Corporate journal"
				}
			},
			{
				"id": "offline",
				"modifier": "offline",
				"targetBlank": true,
				"url": "${rootUrl}/test_url_static/",
				"text": {
					"ru": "Off-line версия",
					"en": "Off-line version"
				}
			}
		]
	},
	"download-pdf": {
		"panelType": "compact",
		"title": {
			"ru": "Скачать PDF",
			"en": "Download PDF"
		},
		"slideType": "vertical",
		"buttonWithText": true,
		"offlineHideButtons": [],
		"buttonsPanelHideButtons": [],
		"sorting": {
			"ru": [
				"pdfPages",
				"pdfSpreads"
			],
			"en": [
				"pdfPages",
				"pdfSpreads"
			]
		},
		"links": [
			{
				"id": "pdfages",
				"modifier": "pdf-pages",
				"targetBlank": true,
				"url": "/download/ru/zebra_brochure_pages.pdf",
				"text": {
					"ru": "Скачать брошюру постранично",
					"en": "Page by page"
				},
				"trackerParams": {
					"ru": {
						"type": "click",
						"url": "ru_buttons-panel-download-pdf_download-pdf-pages"
					},
					"en": {
						"type": "click",
						"url": "en_buttons-panel-download-pdf_download-pdf-pages"
					}
				}
			},
			{
				"id": "pdf-spreads",
				"modifier": "pdf-spreads",
				"targetBlank": true,
				"url": "/download/ru/zebra_brochure_spreads.pdf",
				"text": {
					"ru": "Скачать брошюру разворотами",
					"en": "Spreads"
				},
				"trackerParams": {
					"ru": {
						"type": "click",
						"url": "ru_buttons-panel-download-pdf_download-pdf-spreads"
					},
					"en": {
						"type": "click",
						"url": "en_buttons-panel-download-pdf_download-pdf-spreads"
					}
				}
			}
		]
	},
	"pdf-list": {
		"panelType": "compact",
		"title": {
			"ru": "Скачать брошюру",
			"en": ""
		},
		"slideType": "horizontal",
		"buttonWithText": false,
		"offlineHideButtons": [
			"offline"
		],
		"buttonsPanelHideButtons": [],
		"sorting": {
			"ru": [
				"file",
				"website"
			],
			"en": [
				"file",
				"website"
			]
		},
		"links": [
			{
				"id": "file",
				"modifier": "file",
				"targetBlank": false,
				"url": "/download/ru/zebra_brochure_pages.pdf",
				"text": {
					"ru": "Скачать PDF",
					"en": ""
				},
				"trackerParams": {
					"ru": {
						"type": "click",
						"url": "ru_sidebar-brochure"
					},
					"en": {
						"type": "click",
						"url": "en_sidebar-brochure"
					}
				}
			},
			{
				"id": "website",
				"modifier": "website",
				"targetBlank": true,
				"url": "https://demo.pdf2html.ru/",
				"text": {
					"ru": "Открыть в&nbsp;браузере",
					"en": ""
				}
			}
		]
	}
};
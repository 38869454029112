const config = require('./config.yaml');
const CSearchForm = require('../../index.js');

class CSearchForm_extendable extends CSearchForm {
	constructor() {
		super();
		this.config = config;
	}

	init() {
		this.$searchForm = $('.js-search--extendable');

		if (this.$searchForm.length) {
			this.$searchForm.each((i, elem) => {
				const $form = $(elem);
				const formObj = {
					$form: $form,
					$searchInput: $form.find('.js-search-input'),
					$searchButton: $form.find('.js-search-button'),
					$searchLabel: $form.find('.js-search-label'),
					$searchWrapperInput: $form.find('.js-search-wrapper-input'),
					$searchMessage: $form.find('.js-search-message')
				};

				if (this.config.quickSearch) {
					formObj.$searchResult = formObj.$form.find('.js-search-result');
					formObj.$searchResultList = formObj.$form.find('.js-search-result-list');
					formObj.$searchResultButton = formObj.$form.find('.js-search-result-button');
					this.initQuickSearch(formObj);
				}

				if (this.config.speechRecognition && $('html').hasClass('feature-speechrecognition')) {
					formObj.$searchSpeechButton = formObj.$form.find('.js-search-button-speech');

					this.initSpeechRecognition(formObj);
				}

				if (this.config.closeButton) {
					formObj.$searchCloseButton = formObj.$form.find('.js-search-button-close');

					formObj.$searchCloseButton.on('click', $.proxy(this, 'onCloseButton', formObj));
				}

				formObj.$searchButton.on('click', $.proxy(this, 'onClickButton', formObj));
				formObj.$searchInput
					.on('focus', $.proxy(this, 'onFocusInput', formObj))
					.on('blur', $.proxy(this, 'onBlurInput', formObj));
				formObj.$form.on('submit', $.proxy(this, 'onFormSubmit', formObj));
			});
		}
	}

	onClickButton(formObj, event) {
		this.expandInput(formObj, event);
	}

	onFocusInput(formObj, event) {
		this.setFocusState(formObj, event);
	}

	onBlurInput(formObj, event) {
		this.setBlurState(formObj, event);
	}

	expandInput(formObj, event) {
		const $this = $(event.currentTarget);

		AR.events.emit(`onSearchFormShowInput`, formObj);

		if (!$this.hasClass('is-expand')) {
			event.preventDefault();

			$this.addClass('is-expand');
			$.Velocity(formObj.$searchWrapperInput, this.config.animation.input.show.effect, {
				duration: this.config.animation.input.show.duration,
				begin: () => {
					AR.events.emit(`onSearchFormShowInputBeginAnimation`, formObj);
				},
				complete: () => {
					AR.events.emit(`onSearchFormShowInputEndAnimation`, formObj);
				}
			})
				.then(() => {
					$(document).on('click', $.proxy(this, 'onDocumentListener', formObj));

					return formObj.$searchInput.focus();
				})
				.catch(console.error);

		}
	}

	closeInput(formObj) {
		$(document).off('click', $.proxy(this, 'onDocumentListener'));

		AR.events.emit(`onSearchFormHideInput`, formObj);

		$.Velocity(formObj.$searchWrapperInput, this.config.animation.input.hide.effect, {
			duration: this.config.animation.input.hide.duration,
			begin: () => {
				AR.events.emit(`onSearchFormHideInputBeginAnimation`, formObj);
			},
			complete: () => {
				AR.events.emit(`onSearchFormHideInputEndAnimation`, formObj);
			}
		})
			.then(() => {
				formObj.$searchButton.removeClass('is-expand');
				formObj.$searchMessage.velocity('fadeOut', this.config.animation.input.hide.duration);

				if (this.config.cleanInputOnBlur) {
					formObj.$searchInput.val('');
					formObj.$searchLabel.velocity(this.config.animation.label.show.effect, this.config.animation.label.show.duration);
				}
			});
	}

	onInputTyping(formObj, event) {
		super.onInputTyping(formObj, event);
	}

	onDocumentListener(formObj, event) {
		super.onDocumentListener(formObj, event);
	}
}

AR.waitComponents([], () => {
	const cSearchForm_extendable = new CSearchForm_extendable();

	cSearchForm_extendable.init();

	AR.pushComponent(cSearchForm_extendable, 'cSearchForm_extendable');
});

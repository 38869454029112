const config = require('./config.yaml');
const text = require('./text.yaml')[templateVars.lang];
const CCookieLaw = require('../../index.js');
const cookie = require('cookie_js');

class CCookieLaw_single extends CCookieLaw {
	constructor(config, text) {
		super(config, text);
	}
	// Инициальзация всех существующих событий (клик на кнопки - "Я принимаю", "Подробнее", появление блока при готовом документе)
	init() {
		return new Promise((resolve) => {
			// Если куки с названием - "shown_cookies_law" не имеет параметр - true, и в структуре не стоит параметр запрещающий показ плашки
			if (cookie('shown_cookies_law') != 'true' && !templateVars.page.disableCookieLaw) {
				// После того, как документ будет готов
				$('document').ready(() => {
					this.moreLink = this.getUrl(this.config.linkToPage);
					// Получаем шаблон (данная переменная используется в качеbuttonAlsoTextстве параметра при генерации шаблона в базовом классе - CCookieLaw)
					const notyTemplate = (options) => {
						return `<div class="b-noty__body noty_body">
							<span class="b-noty__title">${options.title}</span>
							<span class="b-noty__text">${options.message} <a href="${this.moreLink}" class="b-cookie-law__btn b-cookie-law__btn--also js-also-btn">${options.buttonAlsoText}</a></span>
							<span class="b-noty__buttons">
								<button class="btn b-cookie-law__btn b-cookie-law__btn--apply js-apply-btn">${options.buttonApplyText}</button>

							</span>
						</div>`;
					};
					// Вызвать метод - createNoty (формирование шаблона блока)
					this.createNoty(notyTemplate)
						.then(() => {
							// Получаем соданную после инициализации плашку
							this.$cookieLawPlate = $('#cookie-law');

							if (this.$cookieLawPlate.length) {
								// Получить кнопку - "Я принимаю"
								this.$applyButton = this.$cookieLawPlate.find('.js-apply-btn');
								// Провесить обработчик для кнопки - "Я принимаю"
								this.$applyButton.on('click', $.proxy(this, 'onClickApplyButton'));
							}
							resolve();
						});
				});
			} else {
				resolve();
			}
		});
	}
}

AR.waitComponents(['cNotification_base'], () => {
	const cCookieLaw_single = new CCookieLaw_single(config, text);
	// Вызов метода, инициализирующего все существующие события
	cCookieLaw_single
		.init()
		.then(() => {
			// Добавление в глобальный объект AR.components
			AR.pushComponent(cCookieLaw_single, 'cCookieLaw_single');
		});
});

import CAligner from '../../index.js';
import config from './config.yaml';

/**
 * Реализует логику варианта blocks компонента aligner
 */
class CAligner_blocks extends CAligner {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		const $rowsElems = $('[data-align-it]');

		if ($rowsElems.length) {
			this.wrapInHelper($rowsElems);
			this.processElements($rowsElems);

			AR.events.on('onTabsHorizontalOpen', $targetArticle => {
				this.processElements($targetArticle.find('[data-align-it]'));
			});

			AR.events.on('onTabsVerticalOpen', $targetArticle => {
				this.processElements($targetArticle.find('[data-align-it]'));
			});

			AR.events.on('onAccordionsBaseOpenStart', $button => {
				setTimeout(() => {
					this.processElements($button.closest('.js-accordion-section').find('[data-align-it]'));
				}, 50);
			});

			$(window).on('resize', () => {
				this.processElements($rowsElems);
			});
		}
	}

	/**
	 * Обработать графики
	 * @param {object} $rowsElems графики
	 */
	processElements($rowsElems) {
		if ($rowsElems.length) {
			$rowsElems
				.removeClass('item--aligned')
				.parent('.js-aligner')
				.css('height', 'auto');

			const $cloned = $rowsElems.clone();
			$.each($rowsElems, (i, item) => {
				//TODO Добавить исключение повторной обработку одних и тех же элементов
				let $row = $rowsElems.filter((cI, cItem) => $(item).offset().top == $(cItem).offset().top);

				this.alignIt($row);
			});
		}
	}

	/**
	 * Обернуть графики в вспомогательные блоки
	 * @param {object} $elems графики
	 * @param {object} wrapperClass класс обертки
	 */
	wrapInHelper($elems, wrapperClass) {
		const classAttr = `class="${wrapperClass ? wrapperClass : this.config.wrapperClass}"`;

		$elems.each((i, elem) => {
			$(elem).wrap(`<div ${classAttr}></div>`);
		});
	}

	/**
	 * Выровнять графики
	 * @param {object} itemsArr графики
	 */
	alignIt($rowItems) {
		let highest = 0;

		$.each($rowItems, (i, elem) => {
			const $elem = $(elem);
			const height = $elem.outerHeight(true);

			if (height > highest) {
				highest = height;
			}
		});

		if (highest !== 0) {
			$rowItems
				.addClass('item--aligned')
				.parent('.js-aligner')
				.css('height', highest);
		}
	}
}

AR.waitComponents([], () => {
	const cAligner_blocks = new CAligner_blocks;
	// Вызов метода, инициализирующего все существующие события
	cAligner_blocks.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cAligner_blocks, 'cAligner_blocks');
});

module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'news/base' && templateVars.config ?
				templateVars.config :
				{
	"showByButton": false,
	"newsInitialCount": 7,
	"newsLoadCount": 6,
	"mainNewsBreakpoints": {
		"medium": "1024px",
		"small": "798px"
	}
};
const CCustomInset = require('../../index.js');

const WIDTH_MOBILE = 768;

class CCustomInset_base extends CCustomInset {
	constructor() {
		super();

		this.stickyCollection = {};
		this.isNotMobile = window.innerWidth > WIDTH_MOBILE;
	}

	async init() {
		// js-custom-inset__title правка
		this.insetCollection = [...document.querySelectorAll('.js-custom-inset .js-custom-inset__title')];

		if (this.insetCollection.length && this.isNotMobile) {
			const StickySidebar = await this.getSickySidebar();

			// document.addEventListener('DOMContentLoaded', () => {
			window.onload = () => { // размеры и координаты зависят от финального рендера стр. ...
				if (!document.querySelector('.browser-ie')) {
					this.insetCollection.forEach((el, id) => {
						this.stickyCollection[`stickyEl_${id}`] = new StickySidebar(el, {topSpacing: 160});
					});
				}
			};
			// });
		}
	}

	async getSickySidebar() {
		const lib = await import(/* webpackChunkName: "stickySidebar" */ 'sticky-sidebar');
		return AR.getEsm(lib);
	}
}

AR.waitComponents([], () => {
	const cCustomInset_base = new CCustomInset_base();
	// Вызов метода, инициализирующего все существующие события
	cCustomInset_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cCustomInset_base, 'cCustomInset_base');
});

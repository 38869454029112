module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'old-browser/base' && templateVars.config ?
				templateVars.config :
				{
	"buttonClass": "b-noty__close",
	"isStatic": false,
	"browsers": {
		"Chrome": {
			"version": "63"
		},
		"Opera": {
			"version": "50"
		},
		"Firefox": {
			"version": "56"
		},
		"Safari": {
			"version": "10"
		},
		"Microsoft Edge": {
			"version": "15"
		},
		"IE": {
			"version": "10"
		},
		"Android Browser": {
			"version": "56"
		}
	},
	"closableOld": false,
	"closableIe": true
};
const CLayout = require('../../index.js');
// const Promise = require('bluebird');
// const cookie = require('cookie_js').cookie;
// const throttle = require('throttle-debounce/throttle');

/**
 * Класс реализует логику для варианта компонента CRegionsMap_base
 */
class CLayout_layout1 extends CLayout {
	constructor() {
		super();

	}

	/**
	 * Инициализация логики работы компонента
	 */
	init() {
		this.$navigationPanel = $('#navigation-panel');

		this.$mainMenuTrigger = $('#main-menu--trigger');
		this.$mainMenuWrapper = $('#main-menu--wrapper');
		this.$mainMenuPanelWrapper = $('#main-menu--panel-wrapper');
		this.$mainMenuPanel = $('#main-menu--panel');

		this.scrollTop = 0;
		this.$page = $('#page');
		this.$layoutWrapper = $('#layout-wrapper');
		this.$contentArea = $('#content-area');
		this.$hideElements = $('.js-above-menu');

		if (!this.$mainMenuPanel.length) {
			return false;
		}

		this.initMainMenuLogic();
		this.initSidebarPanelLogic();
		this.initScrollbars();

		AR.events.on('onMainMenuOpenStart', () => {
			this.closeToolsMenu();
		});

		this.$menuToolsButton = $('.menu-adaptive-tools__button');

		this.$menuToolsButton.on('click', $.proxy(this, 'toggleToolsInMenu'));
	}

	initScrollbars() {
		this.mainMenuInstance = AR.components.cScrollbar_base.initScrollbar(this.$mainMenuPanel, {
			// resize: 'b',
			className: 'os-theme-zebra-main-menu',
			overflowBehavior: {
				x: 'hidden',
				y: 'scroll'
			},
		});
	}

	initMainMenuLogic() {
		if (this.$mainMenuTrigger.length) {
			this.$mainMenuTrigger.on('click', $.proxy(this, 'onMainMenuTriggerToggle'));

			document.addEventListener('keydown', (e) => {
				switch (e.key) {
					case 'Esc': // IE/Edge specific value
					case 'Escape':
						if (this.$mainMenuTrigger.hasClass('is-expand')) {
							$(document).on('click touchstart', $.proxy(this, 'onMenuDocumentListener'));
							this.closeMainMenu();
						}
						break;
				}
			});

		}
	}

	toggleToolsInMenu() {
		const $menuAdaptiveList = $('.menu-adaptive-tools__list');

		if (!$menuAdaptiveList.hasClass('is-active')) {

			this.openToolsMenu();

		} else {
			this.closeToolsMenu();
		}
	}

	openToolsMenu() {
		const $menuAdaptiveList = $('.menu-adaptive-tools__list');

		$.Velocity.animate($menuAdaptiveList, 'slideDown', 400);

		$menuAdaptiveList.addClass('is-active');

		this.$menuToolsButton.addClass('is-expand');
	}

	closeToolsMenu() {
		if (window.innerWidth <= 798) {
			const $menuAdaptiveList = $('.menu-adaptive-tools__list');

			$.Velocity.animate($menuAdaptiveList, 'slideUp', 400);

			$menuAdaptiveList.removeClass('is-active');

			this.$menuToolsButton.removeClass('is-expand');
		}
	}

	onMainMenuTriggerToggle(e) {
		if (this.$mainMenuTrigger.hasClass('is-expand')) {
			$(document).on('click touchstart', $.proxy(this, 'onMenuDocumentListener'));
			this.closeMainMenu();

		} else {
			$(document).on('click touchstart', $.proxy(this, 'onMenuDocumentListener'));
			this.openMainMenu();
		}
	}

	openMainMenu() {
		let $btnWrapper = this.$mainMenuTrigger.parents('.js-btn');

		this.lock();
		this.$navigationPanel.addClass('is-expand--menu');
		this.$navigationPanel.addClass('is-expand');
		this.$navigationPanel.parent().addClass('is-expand');

		this.$mainMenuPanelWrapper.velocity('stop');
		this.$mainMenuPanelWrapper
			.velocity('transition.slideLeftIn', {
				duration: 300,
				begin: (element) => {
					AR.events.emit('onMainMenuOpenStart', element);
					this.hideAboveEls();
				},
				complete: (element) => {}
			});

		$btnWrapper.addClass('is-active');
		this.$mainMenuTrigger.addClass('is-expand');
	}

	closeMainMenu() {
		let $btnWrapper = this.$mainMenuTrigger.parents('.js-btn');

		this.unlock();
		this.$navigationPanel.removeClass('is-expand--menu');
		this.$navigationPanel.removeClass('is-expand');
		this.$mainMenuTrigger.removeClass('is-expand');

		this.$mainMenuPanelWrapper.velocity('stop');
		this.$mainMenuPanelWrapper
			.velocity('transition.slideLeftOut', {
				duration: 300,
				begin: (element) => {
					AR.events.emit('onMainMenuCloseStart', element);
					this.showAboveEls();
				},
				complete: (element) => {
					this.$navigationPanel.parent().removeClass('is-expand');
				}
			});

		$btnWrapper.removeClass('is-active');
	}

	hideAboveEls() {
		this.$hideElements.addClass('is-hidden');
	}

	showAboveEls() {
		this.$hideElements.removeClass('is-hidden');
	}

	onMenuDocumentListener(event) {

		// Если текущий элемент вне меню
		if (!$(event.target).closest(this.$mainMenuWrapper).length && !$('#popup-locker').length && this.$navigationPanel.hasClass('is-expand--menu')) {
			this.closeMainMenu();
		}
	}

	initSidebarPanelLogic() {
		AR.events.on('onButtonsPanelBeforeShowList', (panelObj) => {
			let $btnWrapper = panelObj.$buttonsTrigger.parents('.js-btn');

			if ($btnWrapper.length) {
				$btnWrapper.addClass('is-active');
			}
		});

		AR.events.on('onButtonsPanelBeforeHideList', (panelObj) => {
			let $btnWrapper = panelObj.$buttonsTrigger.parents('.js-btn');

			if ($btnWrapper.length) {
				$btnWrapper.removeClass('is-active');
			}
		});

		AR.events.on('onSearchFormShowInput', (formObj) => {
			let $btnWrapper = formObj.$form.parents('.js-btn');

			if ($btnWrapper.length) {
				$btnWrapper.addClass('is-active');
			}
		});

		AR.events.on('onSearchFormHideInput', (formObj) => {
			let $btnWrapper = formObj.$form.parents('.js-btn');

			if ($btnWrapper.length) {
				$btnWrapper.removeClass('is-active');
			}
		});
	}

	/**
	 * Блокировка
	 */
	lock() {
		this.scrollTop = $(document).scrollTop();
		$('body').addClass('is-locked');

		if (!templateVars.isMainPage) {
			this.$page.css({
				position: 'fixed',
				overflow: 'hidden',
				width: '100%',
				'max-width': this.$layoutWrapper.width()
				// 'top': `-${$(document).scrollTop()}px`,
				// 'height': 'auto'
			});
		}
	}


	/**
	 * Разблокировка
	 */
	unlock() {
		$('body').removeClass('is-locked');

		if (!templateVars.isMainPage) {
			this.$page.css({
				position: '',
				overflow: '',
				width: '',
				'max-width': ''
				// 'top': `auto`,
				// 'height': '100%'
			});
		}

		$(document).scrollTop(this.scrollTop);
	}
}

AR.waitComponents(['cPreloader_base', 'cScrollbar_base'], () => {
	const cLayout_layout1 = new CLayout_layout1();
	// Вызов метода со всеми событиями
	cLayout_layout1.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cLayout_layout1, 'cLayout_layout1');
});

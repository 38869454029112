const main = $(`.js-slider-wrapper--mainpage`);

if (main.length) {
	const waiting = Promise.all([
		new Promise((res, rej) => {
			document.addEventListener('DOMContentLoaded', () => {
				res();
			}, false);
		}),
		new Promise((res, rej) => {
			AR.events.on('svgRenderedAll', $elements => {
				res($elements);
			});
		})
	]);

	AR.waitComponents(['cPreloader_base'], () => {
		init(main, waiting);
	});
}

async function init(mainpage, resources) {
	const {CSlider_mainpage} = await import(/* webpackChunkName: "CSlider_mainpage" */ './main.js');
	const cSlider_mainpage = new CSlider_mainpage(mainpage);
	cSlider_mainpage.init(resources);

	AR.pushComponent(cSlider_mainpage, 'cSlider_mainpage');
}

//базовый класс компонента
const config = require('./config.yaml');
const CBod = require('../../index.js');

/**
 * Реализует логику варианта bod-animated-blocks компонента bod
 */
class CBod_animatedBlocks extends CBod {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация компонента
	 */
	init() {
		this.$bod = $('.js-animated-blocks');
		// по наличию бодов на странице
		if (this.$bod.length) {
			this.$gridContent = this.$bod.find('.js-grid-content');
			this.$bodMain = this.$bod.find('.js-bod-main');
			// событие клика
			this.$bodMain.on('click', $.proxy(this, 'onToggle'));
			// обытие проверки хэша
			// this.checkHash();
		}
	}

	//Временный тогл для смены изображений по тапу в мобильном разрешении
	onToggle(event) {
		const $currentItem = $(event.currentTarget);
		$currentItem.toggleClass('is-expand');
	}

	/**
	 * Смена состояния объекта
	 */
	// onToggle(event) {
	// 	// константа с текущим элементом
	// 	const $currentItem = $(event.currentTarget);

	// 	// проверка открыт ли элемент
	// 	if ($currentItem.hasClass('is-expand')) {
	// 		// вызов метода закрытия
	// 		//триггер глобального события начала закрытия элемента
	// 		AR.events.emit('onBodAnimatedBlocksCloseStart', $currentItem);

	// 		this
	// 			.close($currentItem)
	// 			.then(() => {
	// 				//триггер глобального события окончания закрытия элемента
	// 				AR.events.emit('onBodAnimatedBlocksCloseEnd', $currentItem);
	// 			});
	// 		// вызов метода удаления хэша
	// 		this.removeHash();
	// 	} else {
	// 		// раскрытый элемент
	// 		const $expandedItem = this.getActiveBodItem();

	// 		// проверка существования раскрытого элемента
	// 		if ($expandedItem.length) {
	// 			// вызов метода закрытия
	// 			this
	// 				.close($expandedItem)
	// 				.then(() => {
	// 					// промис метода раскрытия
	// 					this.open($currentItem);
	// 				});
	// 		} else {
	// 			// вызов метода раскрытия текущего элемента
	// 			//триггер глобального события начала раскрытия элемента
	// 			AR.events.emit('onBodAnimatedBlocksOpenStart', $currentItem);

	// 			this
	// 				.open($currentItem)
	// 				.then(() => {
	// 					//триггер глобального события окончания раскрытия элемента
	// 					AR.events.emit('onBodAnimatedBlocksOpenEnd', $currentItem);
	// 				});
	// 		}
	// 	}
	// }

	/**
	 * Метод раскрытия элемента
	 * @param  {object} контент бода
	 * @return {object}
	 */
	open($object) {
		// соседи для js-bod-main с классом .js-bod-content
		const $bodContent = $object.siblings('.js-bod-content');
		// добавление класса раскрытия
		$object.addClass('is-expand');
		// вызов метода пересчета ширины контента
		this.recalcContent();
		// простановка слушателя перерасчета размера окна
		this.addResizeListener($.proxy(this, 'onRecalcContentWidth'));
		// вызов метода простановки хэша
		this.setHash($object);
		// вызов метода скролла к элементу
		this.scrollTo($object);
		// Возвращение анимированного объекта с анимацией раскрытия и добавления стрелки
		return $.Velocity.animate($bodContent, 'slideDown', {
			duration: this.config.bodContentToggleAnimationDuration,
			begin: () => {
				setTimeout(() => this.addContentArrowClass($object), this.config.bodContentToggleAnimationDuration / 3);
			}
		});
	}

	/**
	 * Метод закрытия элемента
	 * @param  {object} контент бода
	 * @return {object}
	 */
	close($object) {
		// соседи для js-bod-main с классом .js-bod-content
		const $bodContent = $object.siblings('.js-bod-content');
		// убирание класса раскрытия


		// this.removeContentArrowClass();
		$object.removeClass('is-expand');
		// снятие слушателя перерасчета размера окна
		this.detachResizeListener($.proxy(this, 'onRecalcContentWidth'));

		// Возвращение анимированного объекта с анимацией закрытия и убирания стрелки
		return $.Velocity
			.animate($bodContent, 'slideUp', {
				duration: this.config.bodContentToggleAnimationDuration,
				begin: () => {
					setTimeout(() => this.removeContentArrowClass($object), this.config.bodContentToggleAnimationDuration / 3);
				}
			});
	}

	/**
	 * Добавить класс добавления стрелки под портретом
	 * @param {object} $item элемента bod'a
	 */
	addContentArrowClass($item) {
		const $targetItem = ($item) ? $item : this.getActiveBodItem();

		$targetItem.addClass('is-arrowed');
	}

	/**
	 * Удалить класс добавления стрелки под портретом
	 * @param {object} $item элемента bod'a
	 */
	removeContentArrowClass($item) {
		const $targetItem = ($item) ? $item : this.getActiveBodItem();

		$targetItem.removeClass('is-arrowed');
	}

	/**
	 * метод по пересчету ширины контента
	 * @param e [пересчет ширины контента бода]
	 * @return {object}   [контент бода]
	 */
	onRecalcContentWidth(e) {
		// метод пересчета ширины контента
		this.recalcContent();
	}

	/**
	 * Пересчет ширины контента
	 * @return {object} [Блок контента бода с измененными размерами и смещенный]
	 */
	recalcContent() {
		// переменная с активным бодом (is-expand)
		const $activeBodItem = this.getActiveBodItem();

		// проверка сущетсования раскрытого бода
		if ($activeBodItem.length) {
			// контент раскрытого бода
			const $activeBodItemContent = $activeBodItem.siblings('.js-bod-content');
			// ширина сетки бодов
			const gridWidth = this.$gridContent.width();
			// получение координатов сетки слева
			const gridOffsetLeft = this.$gridContent.offset().left;
			// получение координатов контента активного бода слева
			const activeBodItemOffsetLeft = $activeBodItem.offset().left;
			// рассчет смещения
			const recalcDelta = activeBodItemOffsetLeft - gridOffsetLeft;
			// выставление ширины контента бода
			$activeBodItemContent.outerWidth(gridWidth);
			// стили смещения контент влево
			$activeBodItemContent.css({
				transform: `translateX(-${recalcDelta}px)`
			});

		}
	}
}

AR.waitComponents([], () => {
	const cBod_animatedBlocks = new CBod_animatedBlocks();
	// Вызов метода, инициализирующего все существующие события
	cBod_animatedBlocks.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cBod_animatedBlocks, 'cBod_animatedBlocks');
});

const CTabs = require('../../index.js');

/**
 * Реализует логику варианта horizontal компонента tabs
 */
class CTabs_horizontal extends CTabs {
	constructor() {
		super();
	}
}

AR.waitComponents([], () => {
	const cTabs_horizontal = new CTabs_horizontal();
	// Вызов метода, инициализирующего все существующие события
	cTabs_horizontal.init('horizontal');
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cTabs_horizontal, 'cTabs_horizontal');
});

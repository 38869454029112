const CForm = require('../../index.js');
const config = require('./config.yaml');
const text = require('./text.yaml')[templateVars.lang];

/**
 * Класс реализует логику для варианта компонента cForm_contact
 */
class CForm_contact extends CForm {
	constructor() {
		//вызываем конструктор родителя
		super();

		//форма
		this.$contactForm = null;
		this.config = config;
	}

	/**
	 * Инициализация логики работы компонента
	 */
	init() {
		//берем формы со страницы
		this.$contactForm = $('.js-form.js-form--contact');

		//если формы найдены
		if (this.$contactForm.length) {
			//инициализация обработки сабмита формы
			this.initFormSubmit(this.$contactForm, config, text);

			//провешивание на ресет формы обработчика с пробросом конфига варианта
			this.$contactForm.on('reset', $.proxy(this, 'onResetForm', config));
			this.addFileValidator();
			//инициализация валидации в форме с пробросом конфига варианта
			this.initValidation(this.$contactForm, config);
			//инициализация полей с масками с пробросом конфига варианта
			this.initMaskPlugin(this.$contactForm, config);
			//инициализация мультиселекта с вакансиями
			this.initMultiselect(this.$contactForm);
			const $upload = this.$contactForm.find('.js-upload-file');
			const $uploadNameEl = this.$contactForm.find('.js-upload-file-name');
			const $popup = this.$contactForm.parents('[data-popup-id]');

			if ($upload.length && $uploadNameEl.length) {
				$upload.on('change', () => this.setFileName($upload, $uploadNameEl));
			}

			//если в конфиге указано использование smart-placeholder, то инициализируем их
			if (this.config.smartPlaceholder) {
				this.initSmartPlaceholder(this.$contactForm, config, text);
			}

			if ($popup.length) {
				this.initInteractiveWithPopup(this.$contactForm, $popup, config);
			}

			// очистка спсика селекта
			AR.events.on('onFormReset', ($currentForm) => {
				$currentForm.find('.multiselect-selected-text').text(text.vacancyList);
				$currentForm.attr('action', `${this.config.submitHandlerUrl.replace('${rootUrl}', templateVars.siteLangRootUrlRel)}`);
			});
		}
	}

	setFormActionRecipients($form, ids) {
		let actionValue = $form.attr('action');
		$form.attr('action', actionValue.replace(/\?.*/, ''));

		if (ids.length) {
			actionValue = $form.attr('action');
			$form.attr('action', `${actionValue}?${this.config.recipientsParam}=${ids}`);
		}
	}

	getOptionsId(options) {
		return options.map(item => item.dataset.id).join(',');
	}

	initMultiselect($form) {
		let $multiselect = $form.find('#multiple-selected');

		$multiselect.multiselect({
			templates: {
				li: '<li><div class="b-checkbox"><label class="b-checkbox__label"></label></div></li>'
			},
			buttonText: (options, select) => {
				let opts = Array.from(options);
				this.setFormActionRecipients($form, this.getOptionsId(opts));

				if (opts.length === 0) {
					return text.vacancyList;
				} else if (opts.length > 3) {
					return text.vacancyListMore;
				} else {
					return opts
						.map(item => {
							let $item = $(item);
							if ($item.attr('label') !== undefined) {
								return $item.attr('label');
							} else {
								return $item.text();
							}
						}).join(', ');
				}
			}
		});

		// чистим error при выборе чекбокса из списка
		$multiselect.on('change', () => {
			this.checkFormValidate($form);
		});

		let $container = $form.find('.multiselect-container');
		let $checkboxes = $container.find('div.b-checkbox');

		$checkboxes.each(function (index) {
			let $this = $(this);
			let id = 'multiselect-' + index,
				$input = $this.find('input');

			$this.find('label').attr('for', id);
			$input.attr('id', id);
			$input.detach();
			$input
				.addClass('b-checkbox__input')
				.prependTo($this);

			$this.click(function (e) {
				e.stopPropagation();
			});
		});

		let $toggleButton = $form.find('.dropdown-toggle');
		$toggleButton.on('click', $.proxy(this, 'hideVisibleMenu', $container, $toggleButton));
	}

	hideVisibleMenu($container, $toggleButton) {
		if (!$toggleButton.hasClass('is-active')) {
			$.Velocity.animate($container, 'slideDown', 400);
			$toggleButton.addClass('is-active');
			$container.addClass('is-expand');

			$(document).on('click touchstart', $.proxy(this, 'onDocumentListener', $toggleButton, $container));

		} else {
			$.Velocity.animate($container, 'slideUp', 400);
			$toggleButton.removeClass('is-active');
			$container.removeClass('is-expand');

			$(document).off('click touchstart', $.proxy(this, 'onDocumentListener', $toggleButton, $container));
		}
	}


	onDocumentListener($toggleButton, $container, event) {
		const $target = $(event.target);

		if (!$target.closest(this.$contactForm).length && $toggleButton.hasClass('is-active')) {

			$.Velocity.animate($container, 'slideUp', 400);
			$toggleButton.removeClass('is-active');
			$container.removeClass('is-expand');
		}
	}

	setFileName($upload, $uploadNameEl) {
		const name = $upload[0].files.length ? $upload[0].files[0].name : '';

		$uploadNameEl.text(name);
	}

	addFileValidator() {
		window.Parsley
			.addValidator('filemaxsize', {
				requirementType: 'string',
				validateString: function (value, requirement, parsleyInstance) {
					const file = parsleyInstance.$element[0].files;

					if (file.length == 0) {
						return true;
					}

					return file.length === 1 && file[0].size <= requirement;

				}
			})
			.addValidator('filemimetypes', {
				requirementType: 'string',
				validateString: function (value, requirement, parsleyInstance) {
					const file = parsleyInstance.$element[0].files;

					if (file.length == 0) {
						return true;
					}

					const allowedMimeTypes = requirement.replace(/\s/g, '').split(',');
					return allowedMimeTypes.indexOf(file[0].type) !== -1;
				}
			});
	}
}

AR.waitComponents(['cCaptcha_base', 'cNotification_base', 'cPopup_base'], () => {
	const cForm_contact = new CForm_contact();
	// Вызов метода со всеми событиями
	cForm_contact.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cForm_contact, 'cForm_contact');
});

const CForm = require('../../index.js');
const config = require('./config.yaml');
const text = require('./text.yaml')[templateVars.lang];

/**
 * Класс реализует логику для варианта компонента cForm_hireUs
 */
class CForm_hireUs extends CForm {
	constructor() {
		//вызываем конструктор родителя
		super();

		//форма
		this.$hireUsForm = null;
		this.config = config;
	}

	/**
	 * Инициализация логики работы компонента
	 */
	init() {
		//берем формы со страницы
		this.$hireUsForm = $('.js-form.js-form--hire-us');

		//если формы найдены
		if (this.$hireUsForm.length) {
			//инициализация обработки сабмита формы
			this.initFormSubmit(this.$hireUsForm, config, text);

			//провешивание на ресет формы обработчика с пробросом конфига варианта
			this.$hireUsForm.on('reset', $.proxy(this, 'onResetForm', config));
			//инициализация валидации в форме с пробросом конфига варианта
			this.initValidation(this.$hireUsForm, config);
			//инициализация полей с масками с пробросом конфига варианта
			this.initMaskPlugin(this.$hireUsForm, config);

			//если в конфиге указано использование smart-placeholder, то инициализируем их
			if (this.config.smartPlaceholder) {
				this.initSmartPlaceholder(this.$hireUsForm, config, text);
			}

			const $popup = this.$hireUsForm.parents('[data-popup-id]');
			if ($popup.length) {
				this.initInteractiveWithPopup(this.$hireUsForm, $popup, config);
			}
		}
	}
}

AR.waitComponents(['cCaptcha_base', 'cNotification_base', 'cPopup_base'], () => {
	const cForm_hireUs = new CForm_hireUs();
	// Вызов метода со всеми событиями
	cForm_hireUs.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cForm_hireUs, 'cForm_hireUs');
});

class CServices {
	init() {
		let blocks = $('.services .js-animation');
		let triggered = 0;

		$('.advantage').css('opacity', '0');
		$('.lines__section').css('opacity', '0');

		$(window).on('scroll mousewheel', animate);

		$(blocks).each(function (i, block) {
			if (isOnTop(block) && !$(block).hasClass('js-animated')) {
				$(block).addClass('js-animated');
				useAnimation(block);
			}
		});

		animate();

		// TODO переделать анимацию
		function animate() {
			$(blocks).each(function (i, block) {
				if (!$(block).hasClass('js-animated')) {
					if (isInView(block)) {
						$(block).addClass('js-animated');

						useAnimation(block);

						triggered++;
					}
				}

				if (triggered === blocks.length) {
					$(window).off('scroll mousewheel', animate);
				}

			});
		}

		function useAnimation(elem) {
			if ($(elem).hasClass('js-display-flex')) {
				$(elem).velocity('transition.fadeIn', {
					display: 'flex'
				});
			} else {
				$(elem).velocity('transition.fadeIn');
			}

			if ($(elem).hasClass('services__advantages')) {
				animateAdvatages(elem);
			}

			if ($(elem).hasClass('services__lines')) {
				animateLines(elem);
			}
		}

		function animateAdvatages(elem) {
			const advatages = $(elem).find('.advantage');
			const advantagesAnimated = $(elem).find('.advantage.js-animated');

			if (advatages.length != advantagesAnimated.length) {
				let animationDelay = 500;
				let animationStep = 750;
				let currentStep = 0;

				$(advatages).each(function (i, block) {
					setTimeout(function () {
						$(block).css('opacity', '1');
						$(block).addClass('js-animated');
					}, animationDelay + animationStep * currentStep++);
				});

			}
		}

		function animateLines(elem) {
			const lines = $(elem).find('.lines__section');
			const linesAnimated = $(elem).find('.lines__section.js-animated');

			if (lines.length != linesAnimated.length) {
				let animationDelay = 500;
				let animationStep = 750;
				let currentStep = 0;

				$(lines).each(function (i, block) {
					setTimeout(function () {
						$(block).css('opacity', '1');
						$(block).addClass('js-animated');
					}, animationDelay + animationStep * currentStep++);
				});

			}
		}
	}

	initMin() {
		// let $mouse = $('.services .js-mouse');
		let $tooltiped = $('.services__navigation-icon');

		// if ($mouse.length) {
		// 	$(window).on('scroll', onScroll);

		// 	function onScroll(ev) {
		// 		$mouse.css('opacity', '0');
		// 		$(window).off('scroll', onScroll);
		// 	}
		// }

		if ($tooltiped.length) {
			$('.services__navigation-icon span').css('display', 'none');

			$tooltiped.each(function (i, el) {
				let $span = $(el).find('span');
				let $el = $(el);

				$el.on('mouseenter', function (ev) {
					if (window.innerWidth > 800) {
						$span.css('display', 'block');
					}

					if (window.innerWidth > 1054) {
						$span.css('left', `-${$span.width() + 25 - $el.hasClass('active') * 25}px`);
					} else {
						$span.css('left', `${($el.width() - $span.width()) / 2 - 5}px`);
					}
				});

				$(el).on('mouseleave', function (ev) {
					$span.css('display', 'none');
				});
			});
		}

	}
}

function isInView(elem) {
	let bounding = elem.getBoundingClientRect();
	return bounding.top >= 0 && bounding.top <= (window.innerHeight || document.documentElement.clientHeight);
}

function isOnTop(elem) {
	let bounding = elem.getBoundingClientRect();
	return bounding.top < 0;
}

AR.waitComponents([], () => {
	const cServices = new CServices();

	// cServices.init();
	// cServices.initMin();

	AR.pushComponent(cServices, 'cServices');
});
